import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import CasterService from '../../../../src/services/service';
import * as Constants from '../../../constants/constants';
import './Files.scss'
import { CircularProgress } from '@material-ui/core';
import { withUserContext } from '../../../contexts/UserContext';

class Files extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openNotification: null,
            severity: null,
            isFileLoading: false,
            isupload: false,
            isDelete: null,
            fileList: []
        }

    }

    componentDidMount = () => {
        this.getFiles()
    }

    getFiles() {
        this.setState({ isFileLoading: true })
        let getJson = { "objectType": "Project", "fileCategory": "files", "projectId": this.props?.project_id, "signedURLType": "downloadFilesList" }
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureUpload`, getJson, null)
            .then((response) => {
                if (response?.data?.error) {
                    this.setState({ openNotification: 'File load Fail', severity: 'error', isFileLoading: false })
                }
                else {
                    let tempFileList = JSON.parse(JSON.stringify([...response?.data?.data]));
                    let fileList = [];
                    if (tempFileList.length > 0) {
                        fileList = tempFileList.sort((a, b) => -a.LastModified.localeCompare(b.LastModified));
                    }

                    console.log("fileList", fileList);
                    this.setState({ isFileLoading: false, fileList })
                }
            },
                (err) => {
                    this.setState({ openNotification: 'File load Fail', severity: 'error', isFileLoading: false })
                })
    }

    deleteFile = (fileName, loader) => {
        this.setState({ isDelete: loader })
        let deleteParams = { "objectType": "Project", "fileCategory": "files", "filename": fileName, "projectId": this.props?.project_id };
        CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureDeleteFiles', deleteParams, null)
            .then(response => {
                if (response.data.error) {
                    this.setState({ openNotification: response?.data?.message || 'File deletion failed', severity: 'error' })
                    return
                }
                else {
                    this.setState({ openNotification: 'File deleted successfully', severity: 'success', isDelete: null })
                    this.getFiles()
                }

            }, (err) => {
                this.setState({ openNotification: 'File deletion failed', severity: 'error' })
            });
    }


    fileUpload = (file) => {
        let uploadFileParams = {
            "objectType": "Project",
            "fileCategory": "files",
            "filename": file?.name,
            "projectId": this.props?.project_id,
            "signedURLType": "upload",
        }

        this.setState({ isupload: true })
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureUpload', uploadFileParams, null)
            .then(response => {
                if (response?.data?.error) {
                    this.setState({ openNotification: response?.data?.message || 'File upload failed', severity: 'error', isupload: false })
                    return
                }
                CasterService.uploadFile(response.data, file).then(res => {
                    if (res?.data?.error) {
                        this.setState({ openNotification: res?.data?.message || 'File upload failed', severity: 'error', isupload: false })
                        return
                    }
                    this.getFiles()
                    this.setState({ isupload: false, openNotification: 'File upload successfully', severity: 'success' })
                }, (err) => {
                    this.setState({ openNotification: 'File upload failed', severity: 'error', isupload: false })
                });
            }, (err) => {
                this.setState({ openNotification: 'File upload failed', severity: 'error', isupload: false })
            });
    }

    downloadFile = (url) => {
        if (url) {
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {
        return (
            <MDBContainer fluid className="ProjectFilesContainer">
                <NotificationComponent
                    open={this.state.openNotification ? true : false}
                    message={this.state.openNotification || ""}
                    severity={this.state.severity || ""}
                    handleResetNotify={() => this.setState({ openNotification: null, severity: null })} />
                {this.state.isFileLoading ? <CircularProgress /> : <MDBRow className='mr-2'>
                    {this.state.fileList?.map(item => {
                        return <MDBCol md={4} style={{ height: "auto" }}>
                            <MDBRow>
                                <MDBCol md={11}>
                                    <span className='pointer grnColor' onClick={(e) => this.downloadFile(item?.url)}>{item?.Key}</span>
                                </MDBCol>
                                {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&            
                                <MDBCol md={1}>
                                    {this.state.isDelete === item?.LastModified ? <CircularProgress color="inherit" size={20} /> : <MDBIcon onClick={(e) => this.deleteFile(item?.Key, item?.LastModified)} className='pointer' icon={'times'}></MDBIcon>}
                                </MDBCol>}
                            </MDBRow>
                        </MDBCol>
                    })}
                    <MDBRow className="footer">
                    <MDBCol className="d-flex justify-content-end align-items-center" style={{ height: "30px", position: 'relative' }}>
                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                        <BasicButton
                            type="inline"
                            className="FR MR1"
                            variant="contained"
                            inputType="file"
                            id='casterFCPerforerFileSectionNew'
                            text={this.state.isupload ? <CircularProgress color="inherit" size={20} /> : "Add File"}
                            uploadFile={this.fileUpload.bind(this)}
                        />}
                    </MDBCol>
                    </MDBRow>
                </MDBRow>}
            </MDBContainer>
        );
    }
}

export default withUserContext(Files);

import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import CasterService from '../../../../src/services/service';
import * as Constants from '../../../constants/constants';
import './Performer.scss'
import { CircularProgress } from '@material-ui/core';
import { withUserContext } from '../../../contexts/UserContext';

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNotification: this.props?.openNotification || null,
      severity: null,
      isFileLoading: this.props?.isFileLoading || false,
      isDelete: null,
      fileList: this.props?.fileList
    }

  }

  componentDidMount = () => {
    this.props?.getFiles()
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps?.fileList !== this.props?.fileList){
      this.setState({fileList: this.props?.fileList})
    }
  }
  

  deleteFile = (fileName, loader) => {
    this.setState({ isDelete: loader })
    let deleteParams = { "objectType": "PerformerDeal", "fileCategory": "files","performerDealId": this.props?.performerDetails?.performer_deal_id, "filename": fileName, "projectId": this.props?.project_id }
    CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureDeleteFiles', deleteParams, null)
      .then(response => {
        if (response.data.error) {
          this.setState({ openNotification: response?.data?.message || 'File deletion failed', severity: 'error' })
          return
        }
        else {
          this.setState({ openNotification: 'File deleted successfully', severity: 'success', isDelete: null })
          this.props?.getFiles()
        }

      }, (err) => {
        this.setState({ openNotification: 'File deletion failed', severity: 'error' })
      });
  }



  downloadFile = (url) => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  render() {
    return (
      <MDBContainer>
        <NotificationComponent
          open={this.state.openNotification ? true : false}
          message={this.state.openNotification || ""}
          severity={this.state.severity || ""}
          handleResetNotify={() => this.setState({ openNotification: null, severity: null })} />
        {this.state.isFileLoading ? <CircularProgress /> : <MDBRow className='mr-2'>
          {this.state.fileList?.map(item => {
            return <MDBCol md={4} style={{height: "auto"}}>
              <MDBRow>
                <MDBCol md={11}>
                  <span className='pointer grnColor' onClick={(e) => this.downloadFile(item?.url)}>{item?.Key}</span>
                </MDBCol>
                {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&          
                <MDBCol md={1}>
                  {this.state.isDelete === item?.id ? <CircularProgress color="inherit" size={20} /> : <MDBIcon onClick={(e) => this.deleteFile(item?.Key, item?.id)} className='pointer' icon={'times'}></MDBIcon>}
                </MDBCol>}
              </MDBRow>
            </MDBCol>
          })}
        </MDBRow>}
      </MDBContainer>
    );
  }
}

export default withUserContext(Files);

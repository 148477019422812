import React from 'react';
import TalentPersonal from './TalentPersonal';
import TalentRepresentative from './TalentRepresentative';
import TalentActingHistory from './TalentActingHistory';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import { validateEmail, maskSsn, getAddress, validateUSNumber, validateIntlNumber, validatePlusCharacter, validatePhoneNumber, ssnFormat, validateEIDNum, checkZipCode, checkFederalIDNum } from '../../Common/Helper';
// import {
//     talentInitialDetails, talentAddress, talentPhone,
//     talentEmail, talentAka, talentLegal, talentMinor, talentRepresentation, talentPersonalLimit,
//     talentContactLimit, addressConfig
// } from './Config';
import { talentFCNavList, initialObjectConfig, initialLoanoutObjectConfig, actingHistoryConfig, actingHistoryFilterConfig } from './Config'
import { withUserContext } from '../../../contexts/UserContext';
import sizeLimits from '../../Common/SizeLimits.json';
import FileCategoryJSON from '../../Common/FileUploadComponent/filecategoryJSON.json';
import './Talent.scss'
import { handlePaginationList, updateTableSortConfigObject, tableSortList, applyFilterTable } from '../../Common/TableHelper';
class TalentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            mode: "new",
            navObj: {},
            isFetchingTalentDetails: false,
            postInitiated: false,
            talentDetails: {},
            countryOptions: [],
            phoneIndex: 2,
            emailIndex: 2,
            addressIndex: 2,
            akaIndex: 2,
            loanoutIndex: 2,
            minorIndex: 2,
            repIndex: 2,
            checkedMultiPrimary: {
                email_list: false,
                phone_list: false,
                address_list: false,
                representation_list: false
            },
            aesKey: '',
            listProdFiles: {
                "signedURLType": "",
                "fileCategory": "",
                "tenantName": props?.userContext?.active_tenant?.tenant_name,
                "objectType": "",
                "fileName": "",
                "uploadLocation": "",
                "showName": "",
                "seasonName": "",
                "showId": null,
                "seasonId": null,
                "episodeId": null,
                "episodeName": "",
                "performerName": null,
                "performerId": null,
                "talentId": null
            },
            performerFiles: [],
            initialAgeValue: "",
            showError: false,
            showLegalMandatory: false,
            sagErrorMessage: "Loanout exists with same SAG",
            ssnErrorMessage: "Performer exists with same SSN",
            ssnError: false,
            sagError: false,
            page: 0,
            rowsPerPage: 10,
            acting_history_list: [],
            backup_acting_history_list: [],
            current_list: [],
            actingHistoryConfig: JSON.parse(JSON.stringify({ ...actingHistoryConfig })),
            actingHistoryFilterList: JSON.parse(JSON.stringify({ ...actingHistoryFilterConfig })),
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            filterInputs: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props?.tabValue !== state?.tabValue) {
            return {
                tabValue: props?.tabValue
            }
        }
    }

    validateErrorFlags = (talentDetails) => {
        let canSubmit = talentDetails?.gender && talentDetails?.ethnicity_list?.length > 0 &&
            // talentDetails?.first_name && talentDetails?.last_name &&
            // talentDetails?.immigration_status &&
            (talentDetails?.ssn ? (ssnFormat(talentDetails?.ssn) ? talentDetails?.ssn?.length <= sizeLimits.formattedSSN : talentDetails?.ssn?.length <= sizeLimits.ssnCharacterLimit) : true) &&
            ((talentDetails?.age < 18 && talentDetails?.birth_date) ? talentDetails?.guardians_list?.length > 0 : true) &&
            (talentDetails?.legal_list?.every(item => item.loanout_company != null ? (item.country_inc === 'United States' || item.country_inc === 'US' || item.country_inc === 'USA' ? item.federal_id_no?.length == 10 && item.federal_id_no?.length == 10 ? checkFederalIDNum(item.federal_id_no) : false : true) : true))
        return canSubmit;
    }

    // checkDuplicate = async (objectType, searchString, checkExistingKey) => {
    //     await CasterService.getData(
    //         Constants.CasterServiceBaseUrl +
    //         `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}&check_existing_key=${checkExistingKey}`,
    //         this.props.userContext?.active_tenant?.tenant_id
    //     )
    //         .then(response => {
    //             console.log("response", response)
    //             if (response?.data) {
    //                 let ssnError = response?.data[0]?.entity_check_name === "SSN" ?
    //                     response?.data[0]?.entity_check_result == 1 ? true : false : false
    //                 let sagError = response?.data[1]?.entity_check_name === "SAG_ID" ?
    //                     response?.data[1]?.entity_check_result == 1 ? true : false : false
    //                 this.setState({ ssnError: ssnError, sagError: sagError })
    //                 // return true;
    //                 // (ssnError || sagError || loanoutError);
    //             }
    //         },
    //             (err) => {
    //                 console.log(err, "error in option")
    //                 // return false;
    //             })
    // }

    componentDidMount() {
        this.getLookupValues('Country');
        this.getLookupValues('Union');
        // this.getUnionDetails('UNION', '');
        this.getTalentDetails();
        if(this.props?.talentId){
            this.getTalentActingHistory(this.props?.talentId)
        }
        if (this.props?.callMethodRef) {
            this.props.callMethodRef.current = {
                resetValues: this.resetValues.bind(this),
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if(this.props?.isEditing !== prevProps?.isEditing && this.props?.isEditing == true){
        //     let talentDetails = JSON.parse(JSON.stringify(this.state?.talentDetails))
        //     if(talentDetails?.legal_list?.length > 0){
        //         let defaultCountry = this.state?.countryOptions?.filter(e=> (e?.label == "United States" || e?.label == "USA" || e?.label == "US"))?.[0]?.label || null; 
        //         talentDetails.legal_list[0].country_inc = defaultCountry;
        //     }
        //     this.setState({talentDetails})
        // }
    }
    formateGuardianList(talentDetails) {
        let formattedGuardian = talentDetails?.guardians_list?.map(item => {
            let isPrimaryPhoneExist = item?.guardian_phone_list?.findIndex(i => i.is_primary == 1)
            let isPrimaryEmailExist = item?.guardian_email_list?.findIndex(i => i.is_primary == 1)
            item.showCollapsible = false;
            item.guardian_phone_list = item?.guardian_phone_list?.map((phone, index) => {
                if ((isPrimaryPhoneExist == index) || (isPrimaryPhoneExist == -1 && index == 0)) {
                    phone.showAsRow = true
                }
                else {
                    phone.showAsRow = false
                }
                phone.phone_with_ext = `+${phone.ext} ${phone.phone}`
                return {
                    object_id: phone?.talent_guardians_phone_id,
                    object_type: phone?.phone_type,
                    value: phone?.phone,
                    is_delete: phone?.is_delete || 0,
                    is_primary: phone?.is_primary,
                    ext: phone?.ext
                }
            })
            item.guardian_email_list = item?.guardian_email_list?.map((email, index) => {
                if ((isPrimaryEmailExist == index) || (isPrimaryEmailExist == -1 && index == 0)) {
                    email.showAsRow = true
                }
                else {
                    email.showAsRow = false
                }
                return {
                    object_id: email?.talent_guardians_email_id,
                    object_type: email?.email_type,
                    value: email?.email,
                    is_delete: email?.is_delete || 0,
                    is_primary: email?.is_primary,
                    ext: null
                };
            })
            return item;
        })
        return formattedGuardian;
    }
    onAddressChange(id, key, value) {
        let addressList = this.state?.talentDetails?.address_list?.length > 0 ? JSON.parse(JSON.stringify(this.state?.talentDetails?.address_list)) : [];
        let addressIndex = addressList?.findIndex(i => i.talent_address_id == id);
        if (key == 'is_primary') {
            addressList?.map(i => i.is_primary = 0);
            addressList[addressIndex][key] = 1;
        } else if (key == 'is_delete') {
            addressList[addressIndex][key] = 1;
        } else if (id) {
            addressList[addressIndex] = value;
        } else {
            let newAddressList = addressList.filter(item => !item.is_delete)
            if (newAddressList?.length == 0) {
                value.is_primary = 1
            } else if (value["is_primary"] === 1) {
                addressList?.map(i => i.is_primary = 0);
            }

            value.talent_address_id = Date.now();
            value.type = "new"
            addressList?.push(value);
        }
        this.handleGeneralEdit('address_list', addressList);
    }
    guardianChange(id, key, value) {
        let guradianList = JSON.parse(JSON.stringify(this.state?.talentDetails?.guardians_list));
        let guradianIndex = guradianList?.findIndex(i => i.talent_guardians_id == id);
        if (key == 'is_primary') {
            guradianList?.map(i => i.is_primary = 0);
            guradianList[guradianIndex][key] = 1;
        } else if (key == 'is_delete') {
            guradianList[guradianIndex][key] = 1;
        }
        else if (key == 'showCollapsible') {
            guradianList[guradianIndex][key] = !guradianList[guradianIndex][key];
        } else if (id) {
            guradianList[guradianIndex] = value;
        }
        else {
            let newGuardianList = guradianList.filter(item => !item.is_delete)
            if (newGuardianList?.length == 0) {
                value.is_primary = 1
            } else if (value["is_primary"] === 1) {
                guradianList?.map(i => i.is_primary = 0);
            }
            // if (guradianList?.length == 0) {
            //     value.is_primary = 1
            // }
            value.talent_guardians_id = Date.now();
            value.type = "new"
            value.showCollapsible = false;
            guradianList?.push(value);
        }
        this.handleGeneralEdit('guardians_list', guradianList)

    }
    addLoanoutCompany(value) {
        this.setState({ showLegalMandatory: false })
        if (value && value?.loanout_company && (value?.country_inc === 'United States' || value?.country_inc === 'USA' || value?.country_inc === 'US' ? value?.federal_id_no?.length == sizeLimits?.intlZipCharacterLimit && checkFederalIDNum(value?.federal_id_no) : true)) {
            let objectList = [...this.state?.talentDetails?.legal_list];
            objectList = objectList?.filter((i, index) => index != 0);
            if (objectList?.length == 0) {
                value.is_primary = 1
            }
            let initObjectConfig = JSON.parse(JSON.stringify(initialLoanoutObjectConfig));
            // let defaultCountry = this.state?.countryOptions?.filter(e=> (e?.label == "United States" || e?.label == "USA" || e?.label == "US"))?.[0]?.label || null; 
            // initObjectConfig.country_inc = defaultCountry;
            value.type = 'new'
            value.talent_legal_id = Date.now();
            objectList = [initObjectConfig, ...objectList, value]
            this.handleGeneralEdit('legal_list', objectList)
        } else {
            this.setState({ showLegalMandatory: true })
        }

    }
    onChangeLoanout(id, key, value) {
        let objectList = [...this.state?.talentDetails?.legal_list];
        let objectIndex = id ? objectList?.findIndex(i => i.talent_legal_id == id) : 0;
        if (key == 'is_primary') {
            objectList?.map(i => i.is_primary = 0);

        }
        objectList[objectIndex][key] = value;
        if (key == 'state_inc') {
            let country = this.state?.countryOptions?.filter(e => (e?.text == "United States" || e?.text == "USA" || e?.text == "US"))?.[0] || null
            objectList[objectIndex].country_inc = country
        }
        if (key == 'country_inc') {
            objectList[objectIndex].state_inc = null
        }

        this.handleGeneralEdit('legal_list', objectList)
    }
    addRemoveObject(list, value) {
        let objectList = [...this.state?.talentDetails?.[list]];
        objectList = objectList?.filter((i, index) => index != 0);

        let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));

        value.type = 'new'
        value.object_id = Date.now();
        let objList = objectList?.filter((i, index) => i.is_delete != 1);
        value.is_primary = objList?.length == 0 ? 1 : 0
        objectList = [initObjectConfig, ...objectList, value]

        this.handleGeneralEdit(list, objectList)
    }
    onChangeObject(list, id, key, value) {
        let objectList = [...this.state?.talentDetails?.[list]];
        let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
        if (key == 'is_primary') {
            objectList?.map(i => i.is_primary = 0);
            value = 1;
        }
        objectList[objectIndex][key] = value;
        this.handleGeneralEdit(list, objectList)
    }
    getAes = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/aesSecretKey`)
            .then(response => {
                let secretKey = response?.data;
                this.setState({ aesKey: secretKey }, () => {
                    if (this.props.mode === "edit") {
                        this.getTalentDetails();
                    }
                })
            },
                (err) => {
                    console.log("Error in AES:", err)
                })
    }

    getAge = (passedDate,origin=null) => {
        if (passedDate!==null) {
            let endDate=null,passedDateFormatted=null;
            if(origin==="death_date"){
                if(this.state.talentDetails?.birth_date){
                    endDate = new Date(passedDate);
                    passedDateFormatted = new Date(this.state.talentDetails?.birth_date);
                }else{
                    return 0
                }
            }else{
                if(this.state.talentDetails?.death_date){
                    endDate = new Date(this.state.talentDetails?.death_date);
                }else{
                    endDate = new Date();
                }
                passedDateFormatted = new Date(passedDate) 
            }
            let age = endDate?.getFullYear() - passedDateFormatted?.getFullYear();
            let m = endDate?.getMonth() - passedDateFormatted?.getMonth();
            if (m < 0 || (m === 0 && endDate?.getDate() < passedDateFormatted?.getDate())) {
                age--;
            }
            age = (age && age > 0) ? age : (age === 0 && age > 0) ? age : 0;
            // this.handleGeneralEdit('age', birthDate ? age : 0);
            return age;
        } else if(origin === "death_date"){
            let endDate=null,passedDateFormatted=null;
            if(this.state.talentDetails?.birth_date){
                endDate = new Date();
                passedDateFormatted = new Date(this.state.talentDetails?.birth_date);
                let age = endDate?.getFullYear() - passedDateFormatted?.getFullYear();
                let m = endDate?.getMonth() - passedDateFormatted?.getMonth();
                if (m < 0 || (m === 0 && endDate?.getDate() < passedDateFormatted?.getDate())) {
                    age--;
                }
                age = (age && age > 0) ? age : (age === 0 && age > 0) ? age : 0;
                return age;
            }else{
                return 0
            }
        }else {
            return 0
        }
    }

    setMinor = (age, birthDate) => {
        let minor = age < 18 ? 1 : 0;
        this.handleGeneralEdit('minor', birthDate ? minor : 0);
        return minor;
    }

    onChangeHandler = (type, value) => {
        let filteredUser = this.state.actingHistoryFilterList
        filteredUser[type] = value
        this.setState({ actingHistoryFilterList: filteredUser })
    }

    addFilterChangeHandler = (field, value) => {
        let newEntry = [`${field} :${value}`]
        let filterInputs = this.state.filterInputs
        let check = filterInputs?.filter((item, index) => {
            var keyValue = item.toString().split(":");
            if (keyValue[0].trim() === field) {
                filterInputs[index] = newEntry
                return item = newEntry
            }
            // var name = keyValue[0].trim();
            // var value = keyValue[1].trim().toLowerCase();
        })
        if (check?.length === 0) {
            filterInputs.push(newEntry)
        }
        var keyValuePair;
        filterInputs = filterInputs?.filter((item, index) => {
            keyValuePair = item.toString().split(":");
            if (keyValuePair[1] !== "") {
                return item
            }
        })
        this.setState({ filterInputs: filterInputs })
        let config = actingHistoryConfig
        let list = this.state.backup_acting_history_list
        let filteredTableData = this.applyFilterTable(config, list, filterInputs, false)
        let data = handlePaginationList(this.state.page, this.state.rowsPerPage, filteredTableData)
        this.setState({ acting_history_list: data }, () => {
            this.setState({ current_list: filteredTableData })
        })
    }

    applyFilterTable = (config, data, filterValues, filtersNone) => {
        var finalList = [];
        var finalResult = data.slice();
        var primaryKey = config.primaryKey;
        if (!filtersNone) {

            filterValues.map((filter) => {
                var keyValue = filter.toString().split(":");
                var name = keyValue[0].trim();
                var value = keyValue[1].trim().toLowerCase();

                var index = -1; // Object.keys(config?.headings).indexOf((item) => config?.headings[item].headingLabel == name);

                Object.keys(config?.headings).map((item) => {
                    console.log(config?.headings[item])
                    if (config?.headings[item].headingLabel == name) {
                        index = item;
                        return;
                    }
                });

                var nodeName = config?.dataNodes[index];
                var filteredList = data?.filter((item) => {
                    if (nodeName == "Type") {
                        return item[nodeName]?.length > 0 ? item[nodeName]?.some(ele => ele?.toString().toLowerCase().indexOf(value) > -1) : false;
                    }
                    else {
                        return item[nodeName] != null ? item[nodeName].toString().toLowerCase().indexOf(value) > -1 : false;
                    }
                })
                finalResult = filteredList.slice()
            });
        }
        else {
            finalResult = data;
        }
        return finalResult;
    }

    getTalentActingHistory = (talent_id) => {
        this.setState({ fetchingAgencyTalents: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureTalentActingHistory?talentId=${talent_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("Error getting Agency Talent Details data");
                    this.setState({ fetchingAgencyTalents: false })
                }
                else {
                    let formattedList = [], actingHistory = []
                    console.log('responseActingHistory', response)
                    actingHistory = response.data
                    actingHistory?.map(item => {
                        item['performer_record'] = "Performer Record";
                        return item;
                    }
                    )
                    console.log('newACtinghistory', actingHistory)
                    // formattedList = talents?.map(item => item.representative)
                    // let agency_talents = formattedList.reduce((t1, t2) => t1.concat(t2), []);
                    this.setState({ fetchingAgencyTalents: false, acting_history_list: handlePaginationList(this.state.page, this.state.rowsPerPage, actingHistory), backup_acting_history_list: actingHistory, current_list: actingHistory },
                        () => {
                            this.handleTableColumnClick("Covered Talent")
                        })
                    // console.log("Get successfull", agency_talents)
                }
            },
                (err) => {
                    this.setState({ fetchingAgencyTalents: false });
                    console.log("Error getting Agency Talent Details data", err);
                })
    }

    handleTableColumnClick = (column) => {
        console.log("this.state.sortBy", this.state.sortBy);
        console.log("column", column);
        console.log("sortCount", this.state.sortCount);
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        let config = actingHistoryConfig
        let list = this.state.acting_history_list
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            // config: updateTableSortConfigObject(configObjectAllUsers, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                actingHistoryConfig: updateTableSortConfigObject(config, this.state.isSortByAsc, this.state.sortBy, colName),
                sortBy: colName,
                acting_history_list: tableSortList("Name", this.getSortNode(colName), list, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Project": return "project_name";
            case "Role": return "role_name";
            case "Union": return "union";
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            acting_history_list: handlePaginationList(newPage, this.state.rowsPerPage, this.state.current_list)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0,
            acting_history_list: handlePaginationList(0, parseInt(event.target.value, 10), this.state.current_list)
        });
    }

    getTalentDetails = () => {
        if (this.props?.talentId) {
            this.setState({ isFetchingTalentDetails: true });
            CasterService.getData(Constants.CasterServiceBaseUrl +
                `/casterFeatureTalentDetails?id=${this.props?.talentId}`, this.props.userContext?.active_tenant?.tenant_id, this.state.aesKey)
                .then(response => {
                    console.log("response", response)
                    if (response?.data?.error || !response?.data) {
                        this.setState({ isFetchingTalentDetails: false })
                    }
                    else {
                        let talentDetails = response?.data?.[0];
                        talentDetails.ssn_type = talentDetails?.f_id ? 'fid' : 'ssn';
                        talentDetails.guardians_list = this.formateGuardianList(talentDetails)
                        if (talentDetails?.birth_date) {
                            talentDetails.age = this.getAge(talentDetails?.birth_date)
                        }
                        // if (talentDetails.akas.length === 0) {
                        //     let alias = {
                        //         "talent_aliases_id": null,
                        //         "aka": talentDetails?.first_name,
                        //         "aka_first_name": talentDetails?.first_name,
                        //         "aka_last_name": null,
                        //         "aka_middle_name": null,
                        //         "aka_suffix": null,
                        //         "is_primary": 1,
                        //         "is_delete": 0
                        //     }
                        //     talentDetails.akas.push(alias);
                        // }
                        let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));
                        let phoneInitObject = JSON.parse(JSON.stringify(initialObjectConfig));
                        let initLoanoutObject = JSON.parse(JSON.stringify(initialLoanoutObjectConfig));
                        // initLoanoutObject.country_inc = 'United States'
                        // if (talentDetails?.legal_list?.length > 0) {
                        //     talentDetails.legal_list = [initLoanoutObject, ...talentDetails.legal_list]
                        // }
                        // else {

                        //     talentDetails.legal_list = []
                        //     talentDetails.legal_list.push(initLoanoutObject);
                        // }
                        if (talentDetails?.legal_list?.length > 0) {
                            let formattedObj = talentDetails?.legal_list?.map(i => {
                                return {
                                    talent_legal_id: i?.talent_legal_id,
                                    loanout_no: i?.loanout_no,
                                    loanout_company: i?.loanout_company,
                                    federal_id_no: i?.federal_id_no,
                                    state_inc: { id: i?.state_inc_id, value: i?.state_inc?.includes("-")?i?.state_inc?.split("-")?.[1]:i?.state_inc, text: i?.state_inc?.includes("-")?i?.state_inc?.split("-")?.[1]:i?.state_inc },
                                    country_inc: { id: i?.country_inc_id, value: i?.country_inc, text: i?.country_inc },
                                    is_primary: i?.is_primary,
                                    is_delete: i?.is_delete
                                }
                            })
                            talentDetails.legal_list = [initLoanoutObject, ...formattedObj]
                        } else {
                            talentDetails.legal_list = [];
                            talentDetails.legal_list.push(initLoanoutObject);
                        }
                        if (talentDetails.email_list?.length > 0) {
                            let formattedObj = talentDetails?.email_list?.map(i => {
                                return {
                                    object_id: i.talent_email_id,
                                    object_type: i.email_type,
                                    value: i.email,
                                    is_primary: i.is_primary,
                                    is_delete: 0
                                }
                            })
                            talentDetails.email_list = [initObjectConfig, ...formattedObj]
                        } else {
                            talentDetails.email_list.push(initObjectConfig);
                        }
                        if (talentDetails?.phone_list?.length > 0) {
                            let formattedObj = talentDetails?.phone_list?.map(i => {
                                return {
                                    object_id: i?.talent_phone_id,
                                    object_type: i?.phone_type,
                                    value: i?.phone,
                                    is_primary: i.is_primary,
                                    is_delete: 0,
                                    ext: i?.ext
                                }
                            })
                            talentDetails.phone_list = [phoneInitObject, ...formattedObj]
                        } else {
                            talentDetails.phone_list = [];
                            talentDetails.phone_list.push(phoneInitObject);
                        }
                        if (talentDetails?.ethnicity_list?.length > 0) {
                            let formattedList = talentDetails?.ethnicity_list?.map(i => {
                                return {
                                    id: i?.talent_ethnicity_id,
                                    value: i?.ethnicity,
                                    text: i?.ethnicity
                                }
                            })
                            talentDetails.ethnicity_list = formattedList
                        }
                        if (talentDetails?.immigration_status) {
                            let immigration_status = { id: talentDetails?.immigration_id, value: talentDetails?.immigration_status, text: talentDetails?.immigration_status }
                            talentDetails.immigration_status = immigration_status
                        }

                        this.setState({ isFetchingTalentDetails: false, talentDetails, storeTalentDetails: talentDetails },()=>{
                            if (this.state.talentDetails?.birth_date) {
                                let age = this.state.talentDetails?.birth_date ? this.getAge(talentDetails?.birth_date) : 0;
                                this.setState(prevState => ({
                                    talentDetails: {
                                        ...prevState.talentDetails,
                                        age
                                    }
                                }))
                            }
                        });
                    }

                    // let formattedResponse = this.formatAgencyDetailsResponse(response[0]);
                    // this.setState({ initialAgeValue: formattedResponse.age })
                    // formattedResponse.age = this.getAge(formattedResponse?.birth_date && new Date(formattedResponse.birth_date))
                    // if (formattedResponse.age && formattedResponse.minor === 1 && formattedResponse.age  >= 18) {
                    //     formattedResponse.minor = this.setMinor(formattedResponse.age, new Date(formattedResponse.birth_date))
                    // }
                    // this.setState({ talentDetails: formattedResponse, isFetchingTalentDetails: false }, this.loadPerformerFilesData);
                    // this.props.setModalList(formattedResponse)
                    // this.state.talentDetails?.address_list?.map((item) => {
                    //     item['address'] = ((!item.address_1) ? '' : item.address_1) + ((!item.address_2) ? '' : ' ' + item.address_2)
                    //         + ((!item.address_3) ? '' : ' ' + item.address_3)
                    // });
                },
                    (err) => {
                        this.setState({
                            isFetchingTalentDetails: false,
                            //  talentDetails: this.formatAgencyDetailsResponse(JSON.parse(JSON.stringify({ ...talentInitialDetails })))
                        })
                        console.log("Error in fetching Talent Details:", err)
                    })
        }
    }

    resetValues = () => {
        let storeTalentDetails = this.state.storeTalentDetails
        this.setState({
            talentDetails: storeTalentDetails
        })
    }

    handleGeneralEdit = (field, value) => {
        let newValue = value == "" ? null : value, age=null;
        if (typeof value === "boolean") {
            newValue = value ? 1 : 0
        }
        let checkValue
        if (field === "ethnicity_list") {
            checkValue = value.filter(
                (value, index, arr) =>
                    index === arr.findIndex((item) => item.value === value.value)
            );
        }
        
        this.setState(prevState => ({
            talentDetails: {
                ...prevState.talentDetails,
                [field]: field === "ethnicity_list" ? checkValue : newValue,
                ...(field == 'birth_date') && { 'age': this.getAge(newValue) },
                ...(field == 'death_date') && { 'age': this.getAge(newValue,"death_date") },
                ...(field == 'ssn') && { 'masked_ssn': maskSsn(newValue) },
                ...(field == 'ssn_type') && { 'ssn': null, 'f_id': null },
            },
            postInitiated: false,
            // ssnError: false,
            // sagError: false
        }), () => {
            if (field === "birth_date" && (value === null || value === "")) {
                this.setState(prevState => ({
                    talentDetails: {
                        ...prevState.talentDetails,
                        death_date:null
                    }
                }))
            }
            this.props.setModalList(this.state.talentDetails);
            this.props.fieldChanged(true, "change");
        })
    }

    handleListEdit = (field, value, obj, listName, check = '') => {
        let talentDetails = { ...this.state.talentDetails };
        let id = (listName === "email_list") ? "talent_email_id" :
            (listName === "phone_list") ? "talent_phone_id" :
                (listName === "address_list") ? "talent_address_id" :
                    (listName === "akas") ? "aka_id" :
                        (listName === "legal_list") ? "talent_legal_id" :
                            (listName === "minors_list") ? "minors_id" :
                                (listName === "representation_list") ? "talent_representative_agency_mtrx_id" : "";
        let list = talentDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                if (field === "is_primary" || field === "is_international") {
                    currentVal[field] = value ? 1 : 0;
                } else if (field === "address") {
                    currentVal = getAddress(field, currentVal, value, addressConfig);
                } else {
                    currentVal[field] = value;
                }
                return currentVal;
            } return currentVal;
        })
        this.setState(prevState => ({
            checkedMultiPrimary: {
                ...prevState.checkedMultiPrimary,
                [listName]: this.checkMultiplePrimary(list)
            }
        }))
        if (check === 'Check') {
            this.initializeCountry(listName, list);
        } else {
            this.handleGeneralEdit(listName, list);
        }
    }

    initializeCountry = (field, value) => {
        this.setState(prevState => ({
            talentDetails: {
                ...prevState.talentDetails,
                [field]: value
            },
            postInitiated: false
        }), () => {
            this.props.setModalList(this.state.talentDetails);
        })
    }

    handleRepListChange = (list, obj, listName) => {
        let talentDetails = { ...this.state.talentDetails };
        let id = "talent_representative_agency_mtrx_id";
        let newList = []
        if (listName === "agency") {
            newList = talentDetails.representation_list?.map(item => {
                if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                    item.agency_id = list?.value || null;
                    item.phone = list?.phone || null;
                    item.agency_email = list?.email || null;
                    item.agency_email_id = list?.emailType || null;
                    item.agency_phone_id = list?.phoneType || null;
                    item.agency_name = list?.text || null;
                    if (!list) {
                        item.address_1 = null;
                        item.address_2 = null;
                        item.address_3 = null;
                        item.city = null;
                        item.country = null;
                        item.country_id = null;
                        item.representative_address_id = null;
                        item.representative_email = null;
                        item.representative_email_id = null;
                        item.representative_id = null;
                        item.representative_name = null;
                        item.representative_phone = null;
                        item.representative_phone_id = null;
                        item.representative_type = null;
                        item.representative_type_id = null;
                        item.state = null;
                        item.state_id = null;
                        item.zip = null;
                        item.is_primary = list?.is_primary || 0;
                    }
                    return item;
                } return item;
            });
        } else if (listName === "representative") {
            newList = talentDetails.representation_list?.map(item => {
                if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                    item.address_1 = list?.address_1 || null;
                    item.address_2 = list?.address_2 || null;
                    item.address_3 = list?.address_3 || null;
                    item.city = list?.city || null;
                    item.country = list?.country || null;
                    item.country_id = list?.country_id || null;
                    item.representative_address_id = list?.address_id || null;
                    item.representative_email = list?.email || null;
                    item.representative_email_id = list?.email_id || null;
                    item.representative_id = list?.representative_id || null;
                    item.representative_name = list?.representative_name || null;
                    item.representative_phone = list?.phone || null;
                    item.representative_phone_id = list?.phone_id || null;
                    item.representative_type = list?.type_name || null;
                    item.representative_type_id = list?.type_id || null;
                    item.state = list?.state || null;
                    item.state_id = list?.state_id || null;
                    item.zip = list?.zip || null;
                    item.is_primary = list?.is_primary || 0;
                    return item;
                } return item;
            });
        }
        this.handleGeneralEdit("representation_list", newList);
    }

    checkMultiplePrimary = (list) => {
        let multiplePrimary = list?.filter(item => item.is_primary === 1);
        return multiplePrimary.length > 1
    }

    formatAgencyDetailsResponse = (response) => {
        //if (response?.email_list?.length === 0) {
        response?.email_list.push(JSON.parse(JSON.stringify({ ...talentEmail })));
        //}
        //if (response?.phone_list?.length === 0) {
        response?.phone_list.push(JSON.parse(JSON.stringify({ ...talentPhone })));
        //}
        //if (response?.address_list?.length === 0) {
        response?.address_list.push(JSON.parse(JSON.stringify({ ...talentAddress })));
        // }
        if (response?.akas?.length === 0) {
            response?.akas.push(JSON.parse(JSON.stringify({ ...talentAka })));

        }
        if (response?.legal_list?.length === 0) {
            response?.legal_list.push(JSON.parse(JSON.stringify({ ...talentLegal })));

        }
        if (response?.minors_list?.length === 0) {
            response?.minors_list.push(JSON.parse(JSON.stringify({ ...talentMinor })));

        }
        return response;
    }

    addAdditionalField = (listName) => {
        let talentDetails = { ...this.state.talentDetails };
        switch (listName) {
            case 'email_list':
                let initialEmailArray = { ...talentEmail };
                let email_list = [...talentDetails.email_list];
                initialEmailArray.index = this.state.emailIndex;
                initialEmailArray.canRemove = true;
                email_list.push(initialEmailArray)
                talentDetails.email_list = email_list;
                this.setState({ emailIndex: this.state.emailIndex + 1, talentDetails: talentDetails });
                break;
            case 'phone_list':
                let initialPhoneArray = { ...talentPhone };
                let phone_list = [...talentDetails.phone_list];
                initialPhoneArray.index = this.state.phoneIndex;
                initialPhoneArray.canRemove = true;
                phone_list.push(initialPhoneArray)
                talentDetails.phone_list = phone_list;
                this.setState({ phoneIndex: this.state.phoneIndex + 1, talentDetails: talentDetails });
                break;
            case 'address_list':
                let initialAddressArray = { ...talentAddress };
                let address_list = [...talentDetails.address_list];
                initialAddressArray.index = this.state.addressIndex;
                initialAddressArray.canRemove = true;
                address_list.push(initialAddressArray)
                talentDetails.address_list = address_list;
                this.setState({ addressIndex: this.state.addressIndex + 1, talentDetails: talentDetails });
                break;
            case 'akas':
                let initialAkaArray = { ...talentAka };
                let akas = [...talentDetails.akas];
                initialAkaArray.index = this.state.akaIndex;
                initialAkaArray.canRemove = true;
                akas.push(initialAkaArray)
                talentDetails.akas = akas;
                this.setState({ akaIndex: this.state.akaIndex + 1, talentDetails: talentDetails });
                break;
            case 'legal_list':
                let initialLegalArray = { ...talentLegal };
                let legal_list = [...talentDetails.legal_list];
                initialLegalArray.index = this.state.loanoutIndex;
                initialLegalArray.canRemove = true;
                legal_list.push(initialLegalArray)
                talentDetails.legal_list = legal_list;
                this.setState({ loanoutIndex: this.state.loanoutIndex + 1, talentDetails: talentDetails });
                break;
            case 'minors_list':
                let initialMinorArray = { ...talentMinor };
                let minors_list = [...talentDetails.minors_list];
                initialMinorArray.index = this.state.minorIndex;
                initialMinorArray.canRemove = true;
                minors_list.push(initialMinorArray)
                talentDetails.minors_list = minors_list;
                this.setState({ minorIndex: this.state.minorIndex + 1, talentDetails: talentDetails });
                break;
        }
    }

    onChangeRepresentative = (id, key, value) => {
        let objectList = [...this.state?.talentDetails?.representation_list];
        let objectIndex = id ? objectList?.findIndex(i => i.talent_representative_agency_mtrx_id == id) : 0;
        if (key == 'is_primary') {
            objectList?.map(i => i.is_primary = 0);
        }
        objectList[objectIndex][key] = value;
        this.handleGeneralEdit('representation_list', objectList)
    }

    addRepresentationList = (value) => {
        if (value && value?.representative_id) {
            let objectList = [...this.state?.talentDetails?.representation_list];
            if (objectList?.length == 0) {
                value.is_primary = 1
            }
            value.type = 'new'
            value.talent_representative_agency_mtrx_id = Date.now();
            objectList = [...objectList, value]
            this.handleGeneralEdit('representation_list', objectList)
        } else {
            // this.setState({showLegalMandatory:true})
        }


    }

    getUnionDetails(objectType, searchString = null) {
        this.setState({ isRepresentativeFetch: true });
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterFeatureSearch?objectType=${objectType}&searchString=`
        ).then(
            (response) => {
                this.setState({ isRepresentativeFetch: false });
                if (response?.data?.error) {
                    console.log("error from search", repsonse?.data?.error);
                } else {
                    let formattedList = response?.data?.map((i) => ({
                        value: i?.production_company,
                        text: i?.production_company,
                    }));
                    this.setState({ prodCompanyOptions: formattedList });

                }
            },
            (err) => {
                this.setState({ isRepresentativeFetch: false });
                console.log("Search from reprsentative: " + err);
            }
        );
    }
    getLookupValues(type) {
        // casterFeatureLookup
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    if (type == 'Country') {
                        let formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
                        this.setState({ countryOptions: formattedList })
                    } else if (type == 'Union') {
                        let formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
                        this.setState({ unionOptions: formattedList })
                    }

                }
            }, err => {
                console.log("error from lookup")
            })

    }
    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 0: return <TalentPersonal
                isEditing={this.props?.isEditing}
                talentDetails={this.state.talentDetails}
                postInitiated={this.state.postInitiated}
                showError={this.state.showError}
                onAliasSave={this.onAliasSave?.bind(this)}
                addLoanoutCompany={this.addLoanoutCompany.bind(this)}
                onChangeLoanout={this.onChangeLoanout.bind(this)}
                showLegalMandatory={this.state?.showLegalMandatory}
                guardianChange={this.guardianChange.bind(this)}
                onAddressChange={this.onAddressChange.bind(this)}
                addRemoveObject={this.addRemoveObject.bind(this)}
                onChangeObject={this.onChangeObject.bind(this)}
                isLoading={this.state.isFetchingTalentDetails}
                getAge={this.getAge}
                handleGeneralEdit={this.handleGeneralEdit}
                ssnError={this.state.ssnError}
                sagError={this.state.sagError}
                ssnErrorMessage={this.state.ssnErrorMessage}
                sagErrorMessage={this.state.sagErrorMessage} />;
            case 1: return <TalentRepresentative
                isEditing={this.props?.isEditing}
                representativeList={this.state.talentDetails.representation_list}
                onChangeRepresentative={this.onChangeRepresentative}
                addRepresentationList={this.addRepresentationList}
                // handleListEdit={this.handleListEdit}
                navFuns={this.props.navFuns}
                postInitiated={this.state.postInitiated}
                isLoading={this.state.isFetchingTalentDetails}
            // handleRepListChange={this.handleRepListChange}
            // checkMultiplePrimary={this.checkMultiplePrimary}
            />;
            case 2: return <TalentActingHistory
                talentId={this.state.navObj.talent_id}
                mode={this.props.mode}
                navFuns={this.props.navFuns}
                actingHistoryConfig={this.state.actingHistoryConfig}
                acting_history_list={this.state.acting_history_list}
                actingHistoryFilterList={this.state.actingHistoryFilterList}
                unionOptions={this.state.unionOptions}
                onChangeHandler={this.onChangeHandler}
                addFilterChangeHandler={this.addFilterChangeHandler}
                handleTableColumnClick={this.handleTableColumnClick.bind(this)}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                backup_acting_history_list={this.state.backup_acting_history_list}
                current_list={this.state.current_list}
                {...this.props}
            />;
            //     case 2: return <AgencyTalent
            //     agencyTalentsConfig={this.state.agencyTalentsConfig}
            //     agency_talent_list={this.state.agency_talent_list}
            //     talentsFilterList={this.state.talentsFilterList}
            //     typeOptions={this.state.typeOptions}
            //     onChangeHandler={this.onChangeHandler}
            //     addFilterChangeHandler={this.addFilterChangeHandler}
            //     handleTableColumnClick={this.handleTableColumnClick.bind(this)}
            //     page={this.state.page}
            //     rowsPerPage={this.state.rowsPerPage}
            //     handleChangePage={this.handleChangePage}
            //     handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            //     backup_agency_talent_list={this.state.backup_agency_talent_list}
            //     navFuns={this.props.navFuns}
            // />
        }
    }

    // postTalentDetails = (talentDetails) => {
    //     this.props.setPostFlag(true);
    //     CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/talentDetails', talentDetails, this.props.userContext?.active_tenant?.tenant_id, 2)
    //         .then((response) => {
    //             if (response.data.error) {
    //                 //Add your error message to alert box from response.data.error
    //                 this.props.fieldChanged(true, "fail");
    //                 this.props.setPostFlag(false);
    //             } else {
    //                 if (this.props.isProfileChanged) {

    //                     this.props.uploadProfile(response.data[0].talent_id);
    //                 }
    //                 this.props.fieldChanged(false, "error");
    //                 this.props.fieldChanged(true, "success");
    //                 this.props.setPostFlag(false);
    //                 if(this.props?.handleTalentCreation) {
    //                     let newTalent = {
    //                         'id': response?.data[0]?.talent_id
    //                      }
    //                     this.props?.handleTalentCreation(newTalent)
    //                 }
    //             }
    //         },(err) => {
    //                 this.props.fieldChanged(true, "fail");
    //                 this.props.setPostFlag(false);
    //                 console.log("Post talent details error: " + err);
    //             });
    // }

    checkIfPrimaryIsChecked = (talentDetails) => {
        if (talentDetails.email_list.length !== 0 && !talentDetails.email_list?.some(item => item.is_primary == 1)) {
            talentDetails.email_list[0].is_primary = 1;
        }
        if (talentDetails.phone_list.length !== 0 && !talentDetails.phone_list?.some(item => item.is_primary == 1)) {
            talentDetails.phone_list[0].is_primary = 1;
        }
        if (talentDetails.address_list.length !== 0 && !talentDetails.address_list?.some(item => item.is_primary == 1)) {
            talentDetails.address_list[0].is_primary = 1;
        }
        if (talentDetails.legal_list.length !== 0 && !talentDetails.legal_list?.some(item => item.is_primary == 1)) {
            talentDetails.legal_list[0].is_primary = 1;
        }
        if (talentDetails.minors_list.length !== 0 && !talentDetails.minors_list?.some(item => item.is_primary == 1)) {
            talentDetails.minors_list[0].is_primary = 1;
        }
        if (talentDetails.representation_list.length !== 0 && !talentDetails.representation_list?.some(item => item.is_primary == 1)) {
            talentDetails.representation_list[0].is_primary = 1;
        }
        return talentDetails;
    }

    setTalentDetailsUniqueEntries = () => {
        let talentDetails = { ...this.state.talentDetails };
        talentDetails.email_list = [...talentDetails.email_list].filter((tag, index, array) =>
            (array.findIndex(t => t.email == tag.email && t.email_type == tag.email_type) == index) &&
            tag.email && tag.email_type);
        talentDetails.phone_list = [...talentDetails.phone_list].filter((tag, index, array) =>
            (array.findIndex(t => t.phone == tag.phone && t.phone_type == tag.phone_type) == index) &&
            tag.phone && tag.phone_type);
        talentDetails.address_list = [...talentDetails.address_list].filter((tag, index, array) =>
            (array.findIndex(t => t.address_1 == tag.address_1 && t.address_2 == tag.address_2 &&
                t.address_3 == tag.address_3 && t.address_type == tag.address_type) == index) && (tag.address_1 ||
                    tag.address_2 || tag.address_3 || tag.city || tag.state || tag.zip));
        talentDetails.legal_list = [...talentDetails.legal_list].filter((tag, index, array) =>
        (array.findIndex(t => t.loanout_no == tag.loanout_no && t.loanout_company == tag.loanout_company &&
            t.federal_id_no == tag.federal_id_no && t.state_inc == tag.state_inc) == index));
        talentDetails.minors_list = [...talentDetails.minors_list].filter((tag, index, array) =>
            (array.findIndex(t => t.guardian_type_id == tag.guardian_type_id && t.guardian_name == tag.guardian_name &&
                t.phone == tag.phone && t.email == tag.email) == index)
            && (talentDetails?.minor === 0 || !talentDetails?.minor ? (tag.guardian_type_id || tag.guardian_name || tag.phone || tag.email) : true));
        talentDetails.akas = [...talentDetails.akas].filter((tag, index, array) =>
            (array.findIndex(t => t.aka == tag.aka) == index) && tag.aka);
        return talentDetails;
    }

    formatPostJSON(postJson) {
        if (postJson?.ssn_type == 'ssn') {
            postJson.f_id = null
            postJson.ssn = postJson.ssn ? postJson.ssn : null;
        } else if (postJson?.ssn_type == 'fid') {
            postJson.ssn = null
            postJson.f_id = postJson.f_id ? postJson.f_id : null;
        }
        // postJson.ethnicity_name = ""
        postJson.email_list = postJson.email_list?.slice(1) || [];
        postJson.phone_list = postJson.phone_list?.slice(1) || [];
        if (postJson.email_list?.length > 0) {
            postJson.email_list = postJson.email_list?.reduce((acc, i) => {
                i.object_id = i?.type == 'new' ? null : i?.object_id
                if (i?.object_id || (!i?.object_id && !i?.is_delete))
                    acc = [...acc, {
                        talent_email_id: i?.object_id,
                        email_type: i?.object_type,
                        email: i?.value,
                        is_delete: i?.is_delete || 0,
                        is_primary: i?.is_primary

                    }]
                return acc;
            }, [])
        }
        if (postJson.phone_list?.length > 0) {
            postJson.phone_list = postJson.phone_list?.reduce((acc, i) => {
                i.object_id = i?.type == 'new' ? null : i?.object_id
                if (i?.object_id || (!i?.object_id && !i?.is_delete))
                    acc = [...acc, {
                        talent_phone_id: i?.object_id,
                        phone_type: i?.object_type,
                        phone: i?.value,
                        is_delete: i?.is_delete || 0,
                        is_primary: i?.is_primary,
                        ext: i?.ext ? parseInt(i?.ext) : null

                    }]
                return acc;
            }, [])
        }
        postJson.guardians_list = postJson?.guardians_list?.reduce((acc, item) => {
            item.talent_guardians_id = item?.type == 'new' ? null : item?.talent_guardians_id;
            if (item?.talent_guardians_id || (!item?.talent_guardians_id && !item?.is_delete)) {
                // item.guardian_email_list = item.guardian_email_list?.length > 0 ? item.guardian_email_list?.slice(1) : [];
                // item.guardian_phone_list = item.guardian_phone_list?.length > 0 ? item.guardian_phone_list?.slice(1) : [];

                item.guardian_email_list = item?.guardian_email_list?.reduce((accEmail, i) => {
                    i.object_id = i?.type == 'new' ? null : i?.object_id
                    if (i?.object_id || (!i?.object_id && !i?.is_delete))
                        accEmail = [...accEmail, {
                            talent_guardians_email_id: i?.object_id,
                            email_type: i?.object_type,
                            email: i?.value,
                            is_delete: i?.is_delete || 0,
                            is_primary: i?.is_primary

                        }]

                    return accEmail;
                }, [])
                item.guardian_phone_list = item?.guardian_phone_list?.reduce((accPhone, i) => {
                    i.object_id = i?.type == 'new' ? null : i?.object_id
                    if (i?.object_id || (!i?.object_id && !i?.is_delete))
                        accPhone = [...accPhone, {
                            talent_guardians_phone_id: i?.object_id,
                            phone_type: i?.object_type,
                            phone: i?.value,
                            is_delete: i?.is_delete || 0,
                            is_primary: i?.is_primary,
                            ext: i?.ext ? parseInt(i?.ext) : null
                        }]
                    return accPhone
                }, [])
                acc = [...acc, item]
            }

            return acc;
        }, [])
        postJson.ethnicity_list = postJson.ethnicity_list?.map(item => ({
            ethnicity: item?.text,
            talent_ethnicity_id: item?.id,
            is_primary: 0,
            is_delete: 0
        }))
        postJson.immigration_status = postJson.immigration_status?.text
        postJson.akas = postJson?.akas?.filter(i => (i?.talent_aliases_id || (!i?.talent_aliases_id && !i?.is_delete)) && !i?.is_delete)
        // postJson.akas = postJson?.akas?.filter(i => !i?.is_delete)
        postJson.address_list = postJson?.address_list?.reduce((acc, item) => {
            item.talent_address_id = item?.type == 'new' ? null : item?.talent_address_id;
            if (item?.talent_address_id || (!item?.talent_address_id && !item?.is_delete)) {
                acc = [...acc, item]
            }
            return acc;
        }, [])
        postJson.legal_list = postJson.legal_list?.slice(1) || [];
        postJson.legal_list = postJson?.legal_list?.reduce((acc, item) => {
            item.talent_legal_id = item?.type == 'new' ? null : item?.talent_legal_id;
            if (item?.talent_legal_id || (!item?.talent_legal_id && !item?.is_delete)) {
                acc = [...acc, item]
            }
            return acc;
        }, [])
        postJson.legal_list = postJson?.legal_list?.map(item => {
            item.country_inc = item?.country_inc?.value
            item.state_inc = item?.country_inc == "United States" || item?.country_inc == "United States" || item?.country_inc == "United States" ? item?.state_inc?.value : null
            return item;
        })
        postJson.representation_list = postJson?.representation_list?.reduce((acc, item) => {
            item.talent_representative_agency_mtrx_id = item?.type == 'new' ? null : item?.talent_representative_agency_mtrx_id;
            if (item?.talent_representative_agency_mtrx_id || (!item?.talent_representative_agency_mtrx_id && !item?.is_delete)) {
                acc = [...acc, item]
            }
            return acc;
        }, [])
        return postJson;
    }
    onAliasSave = (value) => {
        let talentDetails = { ...this.state.talentDetails }
        talentDetails.akas.push(value);
        this.setState({ talentDetails: talentDetails })
    }
    handleTalentSubmit = async () => {
        // let talentDetailsPostJson = this.checkIfPrimaryIsChecked(this.setTalentDetailsUniqueEntries());
        let talentDetailsPostJson = this.formatPostJSON(JSON.parse(JSON.stringify(this.state?.talentDetails)));
        this.setState({ postInitiated: true, showError: false })
        // if (talentDetailsPostJson?.ssn || talentDetailsPostJson?.sag_id) {
        //     await this.checkDuplicate('CHECK_EXISTING', `${talentDetailsPostJson.ssn ? talentDetailsPostJson?.ssn : ""}${talentDetailsPostJson?.sag_id ? ',' + talentDetailsPostJson?.sag_id : ""}`, "SSN,SAG_ID")
        //     if (this.state.ssnError || this.state.sagError) {
        //         this.setState({ postInitiated })
        //         return;
        //     }
        // }
        if (!this.validateErrorFlags(talentDetailsPostJson)) {
            this.setState({ postInitiated: false, showError: true })
            return;
        }
        // console.log(talentDetailsPostJson);
        // return;
        this.props?.setPostFlag(true);
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureTalentDetails', talentDetailsPostJson, this.state?.selecttenant)
            .then((response) => {
                this.props?.setPostFlag(false);
                if (response?.data?.error) {
                    this.setState({ postInitiated: false, showError: false })
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.setState({ postInitiated: false, showError: false })
                    if (this.props.isProfileChanged) {
                        this.props.uploadProfile(response.data[0].talent_id);
                    }
                    this.props?.notificationComponent(true, "successNotClose");
                    this.props.fieldChanged(false, "change");
                    if (this.props?.isOpeningFromPerformer) {
                        this.props?.reloadDetails(this.props?.performer_deal_id)
                    } else if (this.props?.isOpeningFromAdvSearch) {
                        let akas = talentDetailsPostJson?.akas?.filter(i => i?.is_delete === 0)?.map(item => item.aka).join()
                        this.props?.setAkaValues(akas)
                    }
                    this.props?.editModal()
                    console.log("post response", response);
                }
            },
                (err) => {
                    this.props?.setPostFlag(false);
                    this.setState({ postInitiated: false, showError: false })
                    this.props?.notificationComponent(true, "fail");
                    console.log("Post agency details error: " + err);
                });

    }

    render() {
        return (
            <div className="TalentContainer">
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.handleTalentSubmit()}></input>
                {this.getTabComponent()}
            </div>
        );
    }
}
export default withUserContext(TalentContainer);
import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { CircularProgress } from '@material-ui/core';
import moment from "moment";
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import TableComponent from '../../components/SharedComponents/Table';
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import MessageModal from '../../components/SharedComponents/MessageModal';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import { downloadUrl, extractFilenameFromUrl, getFormattedDate } from "../../Common/Helper";
import { deletetalentConfig } from './ShowSeasonConfig';
import { withUserContext } from "../../../contexts/UserContext";
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { talentFilterConfig } from './ShowSeasonConfig';
import AllTalent from './AllTalent';
import './ShowSeason.scss';

let fileFormData = null;
class EpisodeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            end_credits_report: [
                'fileName.pdf'
            ],
            anchorEl: null,
            tabValue: 1,
            selectedEpisode: null,
            talentList: [],
            deletetalentConfig: deletetalentConfig,
            selectOptions: [],
            talentFilters: JSON.parse(JSON.stringify({ ...talentFilterConfig })),
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Talent Name",
            contractAnchorEl: null,
            is_delete: 0,
            severity: null,
            is_delete_flag: false,
            showNotification: false,
            notificationMessage: '',
            is_item: null,
            is_circular: false
        }
    }

    componentDidMount() {
        this.getFilterOptions();
        if (this.props.episodeListRef) {
            this.props.episodeListRef.current = {
                getEpisodePerformers: this.getEpisodePerformers.bind(this),
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState?.selectedEpisode === null || (prevProps.showSeasonId !== this.props?.showSeasonId)) {
            let episodeDetails = { ...this.props?.episodeDetails }
            let selectedEpisode = episodeDetails?.[0];
            selectedEpisode?.talents?.map(item => {
                if (item.representatives?.length > 0) {
                    item['representative'] = item?.representatives[0]?.representative_name;
                    item['agency'] = item?.representatives[0]?.agency_name;
                }
                return item
            })
            this.setState({ selectedEpisode: selectedEpisode }, () => {
                this.getEpisodePerformers();
                this.getFiles();
                this.props.selectedEpisode(selectedEpisode?.episode_id);
            })
        }
    }

    getFilterOptions = () => {
        deletetalentConfig?.headings?.map(data => {
            if (data.inputType === "dropdown") {
                CasterService.getData(Constants.CasterServiceBaseUrl +
                    `/animationGetLookupData?entityType=${data.entityType}`,
                    this.props.userContext.active_tenant.tenant_id)
                    .then(response => {
                        let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item.is_active }));

                        this.setState({ [data.selectOptions]: formattedList });
                    },
                        (err) => {
                            console.log("Error in fetching entities:", err)
                        })
            }
        })
    }

    getFormattedAkas = (akas) => {
        let formattedAkas = [];
        akas?.map(item => {
            formattedAkas?.push(item?.aka);
        })

        return formattedAkas?.join(', ');
    }

    getFormattedReps = (representatives) => {
        let formattedReps = [];
        representatives?.map(item => {
            formattedReps?.push(item?.representative_name);
        })

        return formattedReps?.join('\n ');
    }

    getFormattedAgencies = (representatives) => {
        let formattedAgencies = [];
        representatives?.map(item => {
            formattedAgencies?.push(item?.agency_name);
        })

        return formattedAgencies?.join('\n ');
    }

    getEpisodePerformers = () => {
        // if (this.state.selectedEpisode?.episode_id) {
            this.setState({ isFetchingEpisodePerformers: true })
            let payload = this.state.talentFilters
            payload.show_season_id = this.props?.showSeasonId || null;
            payload.episode_id = this.state.selectedEpisode?.episode_id;
            payload['order_by_clause'] = this.state.sortBy === 'Agency' ? 'Company Name' :
                this.state.sortBy === 'Representative(s)' ? 'Representative Name' : this.state.sortBy;
            payload['order_by'] = this.state.isSortByAsc === true ? 'ASC' : 'DESC';
            CasterService.getData(Constants.CasterServiceBaseUrl +
                `/animationGetPerformers?input=${btoa(JSON.stringify(payload))}`,
                this.props.userContext.active_tenant.tenant_id, 1)
                .then(response => {
                    let episodeTalents = response.data;
                    episodeTalents?.map(item => {
                        if (item?.akas?.length > 0) {
                            item.talent_name = item.talent_name + ' (' + this.getFormattedAkas(item.akas) + ')'
                            item['representative_name'] = this.getFormattedReps(item.representatives);
                            item['company_name'] = this.getFormattedAgencies(item.representatives);
                        }
                        return item
                    })
                    this.setState({
                        episodeTalentList: episodeTalents,
                        isFetchingEpisodePerformers: false
                    }, () => {
                        if (this.props?.talentId) {
                            let performerDetails = this.state.episodeTalentList?.find(item => item.talent_id === this.props?.talentId);
                            this.setState({
                                showEpisodePerformerDetails: true,
                                selectedEpisodeTalent: performerDetails,
                                selectedContract: null
                            },()=>{
                                this.props.selectedPerformer(selectedEpisodeTalent);
                            })
                        }
                    })
                },
                    (err) => {
                        console.log("Error in fetching performers:", err)
                        this.setState({ isFetchingEpisodePerformers: false })
                    })
        // }
    }

    handleEpisodeClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleEpisodeClose = (selectedItem = null, closePopup = false) => {
        let selectedEpisode = selectedItem;
        selectedEpisode.talents?.map(item => {
            if (item.representatives?.length > 0) {
                item['representative'] = item?.representatives[0]?.representative_name;
                item['agency'] = item?.representatives[0]?.agency_name;
            }
            return item
        })
        this.setState({ anchorEl: null, selectedEpisode: selectedEpisode }, () => {
            this.getFiles();
            this.getEpisodePerformers();
            this.props.selectedEpisode(selectedEpisode?.episode_id);
        })
    };

    handleEditEpisode = () => {
        this.setState({ openEditEpisodeModal: true })
    }

    navigationCallback = (dataItem, node) => {
        this.setState({
            showEpisodePerformerDetails: true,
            selectedEpisodeTalent: dataItem,
            selectedContract: null
        })
    }

    applyEpisodeTalentFilter = (filterValues, filtersNone, dropDownValues) => {
        let config = this.state.deletetalentConfig;
        let filtersCopy = JSON.parse(JSON.stringify({ ...talentFilterConfig }))
        if (!filtersNone) {
            filterValues.map((filter) => {
                var keyValue = filter.split(":");
                var name = keyValue[0].trim();
                var value = keyValue[1].trim().toLowerCase();
                var id = dropDownValues?.length !== 0 ? dropDownValues?.filter((item) => item.label.trim().toLowerCase() === value)[0]?.label : null;
                var index = -1;
                var searchKey = "";
                var type = ""
                Object.keys(config?.headings).map((item) => {
                    if (config?.headings[item].headingLabel == name) {
                        index = item;
                        searchKey = config?.headings[item].searchJson;
                        type = config?.headings[item].inputType;
                        return;
                    }
                });
                if (type === "dropdown") {
                    filtersCopy[searchKey] = id + "";
                } else {
                    filtersCopy[searchKey] = value;
                }
                this.setState({
                    talentFilters: filtersCopy, isSortByAsc: true, sortCount: 0, sortBy: "Talent Name"
                }, () => {
                    this.handleTableColumnClick('Talent Name')
                })
            })
        }
        else {
            let filtersJson = JSON.parse(JSON.stringify({ ...talentFilterConfig }))
            this.setState({
                talentFilters: filtersJson, isSortByAsc: true, sortCount: 0, sortBy: "Talent Name"
            }, () => {
                this.handleTableColumnClick('Talent Name')
            })
        }
    }
    
    deletePerformer = (deletePerformer) => {
        deletePerformer ===1 ? this.setState({ openEditEpisodeModal:false, showEpisodePerformerDetails:false, selectedEpisodeTalent: null }) : '';
        this.setState({ showNotification: true, notificationMessage: "Performer Deleted Successfully", severity: 'success', is_circular:false });
        this.getEpisodePerformers();
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                sortBy: colName,
                deletetalentConfig: updateTableSortConfigObject(this.state.deletetalentConfig, this.state.isSortByAsc, this.state.sortBy, colName),
                episodeTalentList: tableSortList(colName, this.getSortNode(colName), this.state.episodeTalentList, this.state.isSortByAsc)
            }, () => {
                this.getEpisodePerformers();
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Talent Name": return "talent_name";
            case "Agency": return "agency_name";
            case "Representative(s)": return "representative_name";
            case "Role Type": return "role_type";
        }
    }

    handleOpenContractPopOver = (id, dataItem, event) => {
        this.setState({
            contractAnchorEl: event.currentTarget,
            selectedPerformer: dataItem
        },()=>{
            this.props.selectedPerformer(this.state.selectedPerformer);
        })
    }

    handleContractRedirection = (contract) => {
        this.setState({
            contractAnchorEl: null,
            showEpisodePerformerDetails: true,
            selectedEpisodeTalent: this.state.selectedPerformer,
            selectedContract: contract
        },()=>{
            this.props.selectedPerformer(this.state.selectedPerformer);
        })
    }

    handleGenerateReport = (report) => {
        let searchPayload = {
            show_id: this.props?.showId,
            show_season_id: this.props?.showSeasonId,
            episode_id: this.state.selectedEpisode?.episode_id,
        };
        let payload = null;
        if (report === 'worksheet-report') {
            this.setState({ isDownloadingWSReport: true })
            payload = {
                payload: "casterAnimationGenerateReport",
                reportName: "rptWorksheet",
                fileName: "rptWorksheet",
                searchJson: btoa(JSON.stringify(searchPayload)),
                showHtml: 0,
                encryptionType: 1
            };
        } else {
            this.setState({ isDownloadingCDReport: true })
            payload = {
                payload: "casterAnimationGenerateReport",
                reportName: "rptCastingData",
                fileName: "rptCastingData",
                searchJson: btoa(JSON.stringify(searchPayload)),
                showHtml: 0,
                encryptionType: 1
            };
        }
        CasterService.getDataWS(payload).then(
            (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200) {
                        const filename = extractFilenameFromUrl(response?.body);
                        let decodedFile = decodeURIComponent(filename)
                        downloadUrl(response?.body, decodedFile);
                        this.setState({ isDownloadingWSReport: false, isDownloadingCDReport: false })
                    }
                })
        );
    }

    selectedPerformer=(selectedPerformerId)=>{
        this.props.selectedPerformer(selectedPerformerId);
    }

    selectedEpisode=(selectedEpisode)=>{
        this.props.selectedEpisode(selectedEpisode);
    }
    

    uploadEndCreditFile = (file, isReplace = false) => {
        fileFormData = new FormData();
        fileFormData.append("files", file);
        let formattedFileList = [{
            "Key": file.name,
            "url": "",
            "id": "1",
        }];
        fileFormData.append('showId', this.props?.showId);
        fileFormData.append('showName', this.props?.showName);
        fileFormData.append('seasonStartYear', this.props.seasonDetails?.start_year);
        fileFormData.append('seasonEndYear', this.props.seasonDetails?.end_year);
        fileFormData.append('episodeName', this.state.selectedEpisode?.episode_name);
        fileFormData.append('episodeGlCode', this.state.selectedEpisode?.gl_code);
        fileFormData.append('moduleType', 'EndCredits');
        if (isReplace) {
            fileFormData.append('isReplaceFile', 1);
            fileFormData.append('replaceFileName', this.state.fileDetails?.Key)
        }
        CasterService.uploadFileFormData(Constants.CasterServiceBaseUrl + `/animationUploadFiles`,
            fileFormData)
            .then(response => {
                console.log("check the file response", response)
                this.getFiles();
            },
                (err) => {
                    console.log('Error in uploading files' + err);
                });
    }

    getFiles = () => {
        let payload = {
            "moduleType": "EndCredits",
            "showId": this.props?.showId,
            "showName": this.props?.showName,
            "seasonStartYear": this.props.seasonDetails?.start_year,
            "seasonEndYear": this.props.seasonDetails?.end_year,
            "episodeName": this.state.selectedEpisode?.episode_name,
            "episodeGlCode": this.state.selectedEpisode?.gl_code
        }
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetFiles?input=${btoa(unescape(encodeURIComponent(JSON.stringify((payload)))))}`,
        this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("check the get files response", response);
                let fileDetails = response?.data;
                this.setState({ fileDetails: fileDetails })
            },
                (err) => {
                    console.log('Error is fetching files' + err);
                });
    }

    handleFileDelete = (fileName) => {
        let payload = {
            "moduleType": "EndCredits",
            "showId": this.props?.showId,
            "showName": this.props?.showName,
            "seasonStartYear": this.props.seasonDetails?.start_year,
            "seasonEndYear": this.props.seasonDetails?.end_year,
            "episodeName": this.state.selectedEpisode?.episode_name,
            "episodeGlCode": this.state.selectedEpisode?.gl_code,
            "fileName": fileName
        }       

        CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + `/animationDeleteFiles`, payload,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("check the delete files response", response);
                this.getFiles();
            },
                (err) => {
                    console.log('Error is fetching files' + err);
                });
    }

    handleSubmit = (is_item,is_delete) => {
        let payload = {
        "is_delete" : is_delete,
        "performer_id" : is_item.performer_id,
        "show_id": 0,
        "show_season_id": 0,
        "representatives": [],
        "talent_id": is_item.talent_id
        }
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostPerformer`,
                payload,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ showNotification: true, notificationMessage: "Performer Deletion Failed", severity: 'error' });
                    } 
                    else if(response.data[0].message === "Performer having signed contracts")
                    {
                        this.setState({ is_delete: 0, showNotification: true, notificationMessage: "Performer cannot be deleted due to some contracts sent for signing or fully signed", severity: 'error', is_delete_flag: false, is_circular:false });
                    }
                    else
                    {
                        this.setState({ is_delete: 0, showNotification: true, notificationMessage: "Performer Deleted Successfully", severity: 'success', is_delete_flag: false, is_circular:false });
                        this.getEpisodePerformers();
                    }
                    
                },
                    (err) => {
                        console.log('Error while deleting talent details' + err);
                        this.setState({ showNotification: true, notificationMessage: "Performer Deletion Failed", severity: 'error' });
                    });
    }

    displayDeletePopup = (item) => {
            this.setState({ is_delete: 1, is_delete_flag: true, is_item:item })
        }

    handleResetNotify = () => {
        this.setState({
            showNotification: false
        });
    }

    render() {
        const open = Boolean(this.state.contractAnchorEl);

        return (
            <MDBContainer fluid className="episodeListContainer p-0">
                <NotificationComponent
                    open={this.state.showNotification}
                    message={this.state.notificationMessage}
                    severity={this.state.severity}
                    handleResetNotify={this.handleResetNotify.bind(this)}
                />
                <MessageModal
                    open={this.state.is_delete_flag}
                    title={"Delete Performer"}
                    message={this.state.is_delete === 1 ? 'Are you sure you want to delete this Performer?, please click Yes to confirm':''}
                    hideCancel={false}
                    primaryButtonText={this.state.is_delete === 1 && 
                        this.state.is_circular ? (<CircularProgress color="inherit" size={18} />) : ("Yes")}
                    secondaryButtonText={this.state.is_delete === 1 ? "NO" : "OK"}
                    handleClose={() => this.setState({ is_delete_flag: false })}
                    onConfirm={(e) => {this.setState({is_circular: true}) 
                                        this.handleSubmit(this.state.is_item,this.state.is_delete)
                    }}
                />
                {this.state.showEpisodePerformerDetails ?
                    <AllTalent
                        showId={this.props?.showId}
                        showType={this.props?.showType}
                        showTypeDropDown={this.props?.showTypeDropDown}
                        showName={this.props?.showName}
                        showSeasonId={this.props?.showSeasonId}
                        seasonName={this.props?.seasonName}
                        episodeId={this.state.selectedEpisode?.episode_id}
                        episodeName={this.state.selectedEpisode?.episode_name}
                        episodeDetails={this.props?.episodeDetails}
                        seasonDetails={this.props?.seasonDetails}
                        productionCompany={this.props?.productionCompany}
                        union={this.props?.union}
                        isFromEpisode={true}
                        selectedContract={this.state.selectedContract}
                        selectedEpisodeTalent={this.state.selectedEpisodeTalent}
                        handleHideTabList={this.props?.handleHideTabList}
                        handleShowTabList={this.props?.handleShowTabList}
                        handleShowEpisodeDetails={() => {
                            this.setState({ showEpisodePerformerDetails: false })
                            this.props?.handleSetTalentId()
                            this.getEpisodePerformers()
                        }}
                        signerDetails={this.props?.signerDetails}
                        selectedEpisode = {this.selectedEpisode}
                        selectedPerformer = {this.selectedPerformer}
                        // {...this.props}
                    /> :
                    <MDBRow>
                        <MDBCol md={3} className="pr-0">
                            <MDBCard>
                                <MDBCardBody>
                                    <MDBCardText>
                                        {this.props?.episodeDetails?.length > 0 ?
                                            <MDBRow>
                                                <div className="episode-menu">
                                                    <Button
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={this.handleEpisodeClick}
                                                        endIcon={<MDBIcon icon="angle-down" />}
                                                    >
                                                        {(this.state.selectedEpisode?.gl_code && this.state.selectedEpisode?.episode_name) ?
                                                            (this.state.selectedEpisode?.gl_code + ' - ' + this.state.selectedEpisode?.episode_name) :
                                                            ''
                                                        }
                                                    </Button>
                                                    <MDBIcon icon="pen" onClick={this.handleEditEpisode} />
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={this.state.anchorEl}
                                                        keepMounted
                                                        open={Boolean(this.state.anchorEl)}
                                                        onClose={() => this.handleEpisodeClose(this.state.selectedEpisode, true)}
                                                    >
                                                        {this.props?.episodeDetails?.map(item => (
                                                            <MenuItem onClick={() => this.handleEpisodeClose(item)}>
                                                                {(item?.gl_code && item?.episode_name) ?
                                                                    (item?.gl_code + ' - ' + item?.episode_name) :
                                                                    ''
                                                                }
                                                            </MenuItem>
                                                        ))}
                                                    </Menu>
                                                </div>
                                            </MDBRow> : <MDBRow>
                                                <span className="no-episodes">No Episodes</span>
                                            </MDBRow>
                                        }
                                        {this.props?.episodeDetails?.length > 0 &&
                                            <>
                                                <MDBRow>
                                                    <BasicLabel text="Recording Dates" />
                                                    <div className="recording-dates">
                                                        <p>From {this.state.selectedEpisode?.start_date ? getFormattedDate(this.state.selectedEpisode?.start_date, false) : '-'}</p>
                                                        <p>To {this.state.selectedEpisode?.end_date ? getFormattedDate(this.state.selectedEpisode?.end_date, false) : '-'}</p>
                                                    </div>
                                                </MDBRow>
                                                <MDBRow>
                                                    <BasicLabel text="Status Dates" />
                                                    {this.state.selectedEpisode?.status_dates?.length > 0 ?
                                                        <div className="status-dates">
                                                            {this.state.selectedEpisode?.status_dates?.map(item => (
                                                                <p>{`${getFormattedDate(item?.start_date, false)} - ${getFormattedDate(item?.end_date, false)} ${item.status ? (': ' + item?.status) : ''}`}</p>
                                                            ))}
                                                        </div> : <div>{'-'}</div>
                                                    }
                                                </MDBRow>
                                                <MDBRow>
                                                    <div className="end-credit-report">
                                                        <BasicLabel text="End Credits Report" />
                                                        {!this.state.fileDetails &&
                                                            <BasicButton
                                                                variant="outlined"
                                                                icon="paperclip"
                                                                type="inline"
                                                                inputType="file"
                                                                multiple={false}
                                                                id={'uploadEndCreditFile'}
                                                                uploadFile={(file) => this.uploadEndCreditFile(file, false)}
                                                            />
                                                        }
                                                    </div>
                                                    {(this.state.fileDetails && this.state.fileDetails?.Key) ?
                                                        <div className="end-credit-report">
                                                            <div className="file-name">{this.state.fileDetails?.Key}</div>
                                                            <BasicButton
                                                                variant="outlined"
                                                                icon="exchange-alt"
                                                                type="inline"
                                                                inputType="file"
                                                                multiple={false}
                                                                id={'uploadEndCreditFile'}
                                                                uploadFile={(file) => this.uploadEndCreditFile(file, true)}
                                                            />
                                                            <MDBIcon icon={"times"} onClick={() => this.handleFileDelete(this.state.fileDetails?.Key)} />
                                                        </div> : <div className="end-credit-report">{'-'}</div>
                                                    }
                                                    {/* ))} */}
                                                </MDBRow>
                                                <MDBRow className="report-btn">
                                                    <MDBCol className="casting-report pr-0">
                                                        <BasicButton
                                                            variant="contained"
                                                            text={this.state.isDownloadingCDReport ?
                                                                <CircularProgress color="inherit" size={18} /> :
                                                                "Casting Data Report"
                                                            }
                                                            onClick={() => this.handleGenerateReport('casting-date-report')}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol className="worksheet-report">
                                                        <BasicButton
                                                            variant="contained"
                                                            text={this.state.isDownloadingWSReport ?
                                                                <CircularProgress color="inherit" size={18} /> : "Worksheet Report"
                                                            }
                                                            onClick={() => this.handleGenerateReport('worksheet-report')}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                            </>
                                        }
                                        <MDBRow className="new-episode-btn">
                                            <MDBCol>
                                                <BasicButton
                                                    variant="outlined"
                                                    text={"New Episode"}
                                                    icon="plus-circle"
                                                    onClick={() => this.setState({ openNewEpisodeModal: true })}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md={9}>
                            <MDBRow>
                                <div className="episode-performers talent-record-btn-header">
                                    <div></div>
                                    <div className="new-talent-btn">
                                        <BasicButton
                                            variant="contained"
                                            text={"New Talent Record"}
                                            icon="plus-circle"
                                            onClick={() => this.setState({ openNewTalentModal: true })}
                                        />
                                    </div>
                                </div>
                            </MDBRow>
                            <MDBCard>
                                <MDBCardBody>
                                    <MDBCardText>
                                        <MDBRow>
                                            <div className="episode-performers">
                                                <BasicLabel
                                                    text={`Talent appearing in ${this.state.selectedEpisode?.episode_name ? `${this.state.selectedEpisode?.episode_name} (${this.state.selectedEpisode?.gl_code})` : '-'}`}
                                                />
                                                {/* <div className="new-talent-btn">
                                                    <BasicButton
                                                        variant="contained"
                                                        text={"New Talent Record"}
                                                        icon="plus-circle"
                                                        onClick={() => this.setState({ openNewTalentModal: true })}
                                                    />
                                                </div> */}
                                            </div>
                                        </MDBRow>
                                        <MDBRow className="talent-table">
                                            <TableComponent
                                                list={this.state.episodeTalentList || []}
                                                isLoading={this.state.isFetchingEpisodePerformers}
                                                config={this.state.deletetalentConfig}
                                                options={this.state}
                                                hyperLinkNavigationCallback={this.navigationCallback}
                                                applyFilter={this.applyEpisodeTalentFilter.bind(this)}
                                                sortCallback={this.handleTableColumnClick}
                                                handleOpenInlineModal={this.handleOpenContractPopOver.bind(this)}
                                                handleDelCallBack={(id, item) => {this.displayDeletePopup(item)}}
                                            />
                                            {this.state.selectedPerformer?.contracts?.length > 0 &&
                                                <Popover
                                                    id={'contract-popover'}
                                                    open={open}
                                                    anchorEl={this.state.contractAnchorEl}
                                                    onClose={() => this.setState({ contractAnchorEl: null })}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    {this.state.selectedPerformer?.contracts?.map(item => (
                                                        <MDBRow onClick={() => this.handleContractRedirection(item)}>
                                                            <p className="performer-contract">
                                                                {`${item?.gl_code ? item?.gl_code : ''}${item?.episode_name ? (' - ' + item?.episode_name) : ''}${item?.contract_date ? (' - ' + moment(item?.contract_date).format("MM/DD/YYYY")) : ''}`}
                                                            </p>
                                                        </MDBRow>
                                                    ))}
                                                </Popover>
                                            }
                                        </MDBRow>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                }
                {this.state.openNewEpisodeModal && (
                    <MainViewModalComponent
                        open={this.state.openNewEpisodeModal}
                        handleClose={() => this.setState({ openNewEpisodeModal: false })}
                        headerText={"New Episode"}
                        mode={"New"}
                        modalName={"Episode"}
                        showId={this.props?.showId}
                        showSeasonId={this.props?.showSeasonId}
                        toggleRefresh={this.props?.toggleRefresh}
                    />
                )}
                {this.state.openEditEpisodeModal && (
                    <MainViewModalComponent
                        open={this.state.openEditEpisodeModal}
                        handleClose={() => this.setState({ openEditEpisodeModal: false })}
                        headerText={"Edit Episode"}
                        mode={"Edit"}
                        modalName={"Episode"}
                        showId={this.props?.showId}
                        showSeasonId={this.props?.showSeasonId}
                        episodeId={this.state.selectedEpisode?.episode_id}
                        toggleRefresh={this.props?.toggleRefresh}
                        deletePerformer={this.deletePerformer.bind(this)}
                    />
                )}
                {this.state.openNewTalentModal && (
                    <MainViewModalComponent
                        open={this.state.openNewTalentModal}
                        handleClose={() => this.setState({ openNewTalentModal: false })}
                        headerText={"New Talent Record"}
                        mode={"New"}
                        modalName={"Talent Record"}
                        showId={this.props?.showId}
                        showSeasonId={this.props?.showSeasonId}
                        episodeId={this.state.selectedEpisode?.episode_id}
                        toggleRefresh={this.getEpisodePerformers}
                        navigationCallback={this.navigationCallback}
                        deletePerformer={this.deletePerformer.bind(this)}
                    />
                )}
            </MDBContainer>
        )
    }
}

export default withUserContext(EpisodeList);
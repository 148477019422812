import React, { Component } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import Tooltip from "@material-ui/core/Tooltip";
import { CircularProgress } from "@material-ui/core";
import SearchFieldWithAddValue from "../SharedComponents/SearchFieldWithAddValue";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import "./ContactsAndPayments.scss";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import MessageModal from "../SharedComponents/MessageModal";
import Modal from "../../Common/MainViewModalLayout/Modal/Modal";
import { withUserContext } from "../../../contexts/UserContext";
import ModalComponent from "../../Common/DefaultModalLayout/Modal/Modal";
import NewRepresentative from "../../Common/NewRepresentative/NewRepresentative";
import {
  representativeFCNavList,
  agencyNavList,
} from "../Representative/config";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
class ContactsAndPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      representativeSelected: null,
      representativeOptions: [],
      isRepresentativeFetch: false,
      isDefaultPost: false,
      isPERFORMERL:false,
      isREPRESENTATIVE:false,
      representativeList: [],
      performerList: [],
      guardianList: [],
      sendToPaymentList: [],
      showDeleteConfirmPopup: false,
      selectedDelRep: null,
      isDeleteRep: false,
      showEditRepresentative: false,
      openEditPerformerContactModal: false,
      showSendPaymentsTo: false,
      openRepresentativePopOver: false,
      setRepresentativePopOver: null,
      showRepresentativeModal: false,
      addLoader: false,
      showAgencyPopup: false,
      showNotes: false,
      repId: null,
      selectedRep: null,
      showRepresentativePopup: false,
      deleteSendPaymentsTo: false,
      deleteloading:false
    };
  }
  componentDidMount() {
    this.getContactDetails("REPRESENTATIVE");
    this.getContactDetails("PERFORMER");
    this.getContactDetails("GUARDIANS");
    this.getContactDetails("PAYMENTSTO");
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.refreshPage?.value !== this.props?.refreshPage?.value) {
      if (this.props?.refreshPage?.modal === "ContactsAndPaymentsTabRep") {
        this.getContactDetails("REPRESENTATIVE");
        this.props?.toggleRefreshPage("");
      } else if (
        this.props?.refreshPage?.modal === "ContactsAndPaymentsTabPerformer"
      ) {
        this.getContactDetails("PERFORMER");
        this.getContactDetails("GUARDIANS");
        this.props?.toggleRefreshPage("");
      } else if (
        this.props?.refreshPage?.modal === "ContactsAndPaymentsTabPayments"
      ) {
        this.getContactDetails("PAYMENTSTO");
        this.props?.toggleRefreshPage("");
      }
    }
  }

  getObjectDetails(objectType, searchString = null) {
    this.setState({ isRepresentativeFetch: true });
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`
    ).then(
      (response) => {
        this.setState({ isRepresentativeFetch: false });
        if (response?.data?.error) {
          console.log("error from search", repsonse?.data?.error);
        } else {
          let formattedList = response?.data?.map((i) => ({
            value: i?.id,
            text: i?.name,
            aka: i?.aka,
            headerName:
              objectType == "Representative" ? i?.representative_name : null,
          }));

          if (objectType == "Representative") {
            this.setState({ representativeOptions: formattedList });
          }
        }
      },
      (err) => {
        this.setState({ isRepresentativeFetch: false });
        console.log("Search from reprsentative: " + err);
      }
    );
  }
  onChange(value, e) {
    if (value) {
      if (value?.optionType == "new") {
        this.setState({
          setRepresentativePopOver: document.getElementById(
            "repFieldContactAndPay"
          ),
          openRepresentativePopOver: true,
          selectedRep: value,
        });
      } else if (value?.optionType === "edit") {
        this.setState({
          selectedRep: value,
          representativeSelected: value,
          showRepresentativeModal: true,
        });
      } else {
        this.setState({ representativeSelected: value });
      }
    } else {
      this.setState({
        openRepresentativePopOver: false,
        setRepresentativePopOver: null,
        representativeSelected: null,
      });
    }
  }
  getContactDetails(type) {
    this.setState({ ['is'+type]: true });
    if (this.props?.performer_deal_id)
      CasterService.getData(
        Constants.CasterServiceBaseUrl +
        `/casterFeaturePerformerDealContactList?performer_deal_id=${this.props?.performer_deal_id}&type=${type}`
      ).then(
        (response) => {
          this.setState({ ['is'+type]: false });
          if (response?.data?.error) {
            console.log("error from search", repsonse?.data?.error);
          } else {
            let formattedList = response?.data;

            if (type == "REPRESENTATIVE") {
              this.setState({ representativeList: formattedList });
            } else if (type == "GUARDIANS") {
              this.setState({ guardianList: formattedList });
            } else if (type == "PERFORMER") {
              this.setState({ performerList: formattedList[0] }, () => {
                this.setState({ openEditPerformerContactModal : this.props?.newMinorOpenEPCModal ? this.props?.newMinorOpenEPCModal : false })
              });
            } else if (type == "PAYMENTSTO") {
              this.setState({ sendToPaymentList: formattedList });
            }
          }
        },
        (err) => {
          this.setState({ ['is'+type]: false });
          console.log("Search from reprsentative: " + err);
        }
      );
  }
  getFullAddress(item, type) {
    if (type == "performer") {
      let { address, city, state, country, zip } = item;
      if (state?.includes('-')) {
        let splitArray = state?.split("-")
        state = splitArray[0]
      }
      return <div>
        {address ? <p> {address} </p> : ''}
        {<p>{`${city ? city : ''} ${state ? city ? ', ' + state : state : ''} ${zip ? state ? ' ' + zip : city ? ', ' + zip : zip : ''}`}</p>}
        {country ? (country !== "United States" && country !== "USA" && country !== "US") ? <p> {country} </p> : '' : ''}
      </div>
      //     return `${address ? address + "," : ""} ${city ? city + "," : ""} ${state ? state + "," : ""
      // } ${country ? country + "," : ""} ${zip ? zip : ""}`;
    } else if (type == "representative") {
      let { agency_address, agency_city, agency_state, agency_country, agency_zip } = item;
      if (agency_state?.includes('-')) {
        let splitArray = agency_state?.split("-")
        agency_state = splitArray[0]
      }
      return <div>
        {agency_address ? <p> {agency_address} </p> : ''}
        {<p>{`${agency_city ? agency_city : ''} ${agency_state ? agency_city ? ', ' + agency_state : agency_state : ''}${agency_zip ? agency_state ? ' ' + agency_zip : agency_city ? ', ' + agency_zip : agency_zip : ''}`}</p>}
        {agency_country ? (agency_country !== "United States" && agency_country !== "USA" && agency_country !== "US") ? <p> {agency_country} </p> : '' : ''}
      </div>
    } else if (type == "send_payments_to") {
      let { entity_address, entity_city, entity_state, entity_country, entity_zip } = item;
      if (entity_state?.includes('-')) {
        let splitArray = entity_state?.split("-")
        entity_state = splitArray[0]
      }
      return <div>
        {entity_address ? <p> {entity_address} </p> : ''}
        {<p>{`${entity_city ? entity_city : ''} ${entity_state ? entity_city ? ', ' + entity_state : entity_state : ''}${entity_zip ? entity_state ? ' ' + entity_zip : entity_city ? ', ' + entity_zip : entity_zip : ''}`}</p>}
        {entity_country ? (entity_country !== "United States" && entity_country !== "USA" && entity_country !== "US") ? <p> {entity_country} </p> : '' : ''}
      </div>
    }
    else {
      let { guardian_address, guardian_city, guardian_state, guardian_country, guardian_zip } = item;
      if (guardian_state?.includes('-')) {
        let splitArray = guardian_state?.split("-")
        guardian_state = splitArray[0]
      }
      return <div className="guardianDiv">
        {guardian_address ? <p> {guardian_address} </p> : ''}
        {<p>{`${guardian_city ? guardian_city : ''} ${guardian_state ? guardian_city ? ', ' + guardian_state : guardian_state : ''}${guardian_zip ? guardian_state ? ' ' + guardian_zip : guardian_city ? ', ' + guardian_zip : guardian_zip : ''}`}</p>}
        {guardian_country ? (guardian_country !== "United States" && guardian_country !== "USA" && guardian_country !== "US") ? <p> {guardian_country} </p> : '' : ''}
      </div>
    }
  }
  onCheckboxChange(id, key, value, type) {
    if (type === "REPRESENTATIVE") {
      let representativeList = this.state?.representativeList;
      let performerList = this.state?.performerList;
      let repIndex = representativeList?.findIndex(
        (i) => i.performer_deal_representative_id == id
      );
      if (key == "is_primary") {
        // representativeList?.map(i => i.is_primary = 0);
        representativeList[repIndex][key] = value == true ? 1 : 0;
      }
      this.setState({ representativeList: representativeList });
      this.checkBoxPost(representativeList, performerList, type);
    }
    if (type === "PERFORMER") {
      let representativeList = this.state?.representativeList;
      let performerList = this.state?.performerList;
      if (key == "no_phone_on_contract") {
        performerList[key] = value == true ? 1 : 0;
      }
      if (key == "no_address_on_contract") {
        performerList[key] = value == true ? 1 : 0;
      }
      this.setState({ performerList: performerList });
      this.checkBoxPost(representativeList, performerList, type);
    }
  }

  openRepresentative = (dealId, repId) => {
    this.setState({
      repId: repId,
      dealId: dealId,
      showEditRepresentative: true,
    });
  };

  checkBoxPost = (repList, perfList, type) => {
    let obj = {};
    let representativeList = repList?.map((item) => ({
      performer_deal_representative_id: item.performer_deal_representative_id,
      is_primary: item.is_primary
        ? item.is_primary
        : 0,
      is_send_to_contract: item.is_send_to_contract
        ? item.is_send_to_contract
        : 0,
      no_address_on_contract: 0,
      no_phone_on_contract: 0

    }));
    let performerList = [
      {
        performer_deal_id: perfList?.performer_deal_id,
        is_primary: perfList?.is_primary
          ? perfList?.is_primary
          : 0,
        is_send_to_contract: perfList?.is_send_to_contract
          ? perfList?.is_send_to_contract
          : 0,
        no_address_on_contract: perfList?.no_address_on_contract
          ? perfList?.no_address_on_contract
          : 0,
        no_phone_on_contract: perfList?.no_phone_on_contract ? perfList?.no_phone_on_contract : 0
      },
    ];
    obj = { representative: representativeList, performer: performerList };
    this.setState({isDefaultPost:true})
    CasterService.postDataParams(
      Constants.CasterServiceBaseUrl +
      `/casterFeaturePerformerDealContactDefaults`,
      obj
    ).then(
      (response) => {
        this.setState({isDefaultPost:false });
        if (response?.data?.error) {
          console.log("error from search", repsonse?.data?.error);
          this.props?.notificationComponent(true, "fail");
        } else {
          this.setState({ representativeSelected: null });
          this.props?.notificationComponent(true, "success");
          this.getContactDetails("PERFORMER");
          this.getContactDetails("REPRESENTATIVE");
        }
      },
      (err) => {
        this.setState({isDefaultPost:false });
        this.props?.notificationComponent(true, "fail");
        console.log("add deal reprsentative: " + err);
      }
    );
  };

  onRadioChange(id, key, value, type) {
    if (type === "REPRESENTATIVE") {
      let representativeList = this.state?.representativeList;
      let performerList = this.state?.performerList;
      let repIndex = representativeList?.findIndex(
        (i) => i.performer_deal_representative_id == id
      );
      if (key == "is_send_to_contract") {
        representativeList?.map((i) => (i.is_send_to_contract = 0));
        performerList["is_send_to_contract"] = 0;
        representativeList[repIndex][key] = value == true ? 1 : 0;
      }
      this.setState({ representativeList: representativeList });
      this.checkBoxPost(representativeList, performerList, type);
    }
    if (type === "PERFORMER") {
      let performerList = this.state?.performerList;
      let representativeList = this.state?.representativeList;
      if (key == "is_send_to_contract") {
        representativeList?.map((i) => (i.is_send_to_contract = 0));
        performerList[key] = value == true ? 1 : 0;
      }
      this.setState({ performerList: performerList });
      this.checkBoxPost(representativeList, performerList, type);
    }
  }

  handleShowHideDeletePopup(value, obj = null) {
    this.setState({ selectedDelRep: obj , showDeleteConfirmPopup : value});
  }
  showDeleteSendPaymentToModal() {
    this.setState({  deleteSendPaymentsTo : true});  
  }
  deleteConfirmation(type) {
    this.removeRepresentative(type);
  }

  removeRepresentative(type) {
    if (this.state?.selectedDelRep?.id) {
      // hit the remove representative API
      let postJson = {
        performer_deal_representative_id: this.state?.selectedDelRep?.id,
        representative_id: this.state?.selectedDelRep?.representative_id,
        is_delete: 1,
        performer_deal_id: this.props?.performer_deal_id,
        is_permanent: type == "permanent" ? 1 : 0,
      };
      this.setState({ isDeleteRep: type });
      CasterService.postDataParams(
        Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealRep`,
        postJson
      ).then(
        (response) => {
          this.setState({ isDeleteRep: false });
          if (response?.data?.error) {
            console.log("error from search", repsonse?.data?.error);

            this.props?.notificationComponent(true, "fail");
          } else {
            this.handleShowHideDeletePopup(false);
            this.getContactDetails("REPRESENTATIVE");
            this.props?.getTalentPerformerDetails(
              this.props?.performer_deal_id
            );
            this.props?.notificationComponent(true, "success");
          }
        },
        (err) => {
          this.setState({ isDeleteRep: false });
          this.props?.notificationComponent(true, "fail");
          console.log("add deal reprsentative: " + err);
        }
      );
    }
  }

  addRepresentative() {
    if (this.state?.representativeSelected) {
      // hit the API for add representative
      let postJson = {
        performer_deal_representative_id: null,
        representative_id: this.state?.representativeSelected?.value,
        is_delete: 0,
        performer_deal_id: this.props?.performer_deal_id,
      };
      this.setState({ addLoader: true });
      CasterService.postDataParams(
        Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealRep`,
        postJson
      ).then(
        (response) => {
          this.setState({ addLoader: false });
          if (response?.data?.error) {
            console.log("error from search", response?.data?.error);
            this.props?.notificationComponent(true, "fail");
          } else {
            this.setState({ representativeSelected: null });
            this.props?.notificationComponent(true, "success");
            this.props?.getTalentPerformerDetails(
              this.props?.performer_deal_id
            );
            this.getContactDetails("REPRESENTATIVE");
          }
        },
        (err) => {
          this.setState({ addLoader: false });
          this.props?.notificationComponent(true, "fail");
          console.log("add deal reprsentative: " + err);
        }
      );
    }
  }

  openEditPerformerContacts = () => {
    this.setState({ openEditPerformerContactModal: true });
  };

  handleClose = () => {
    let performerList = this.state.performerList;
    performerList.email = performerList.email?.slice(1) || [];
    performerList.phone = performerList.phone?.slice(1) || [];
    if (performerList.email?.length > 0) {
      performerList.email = performerList.email?.reduce((acc, i) => {
        i.object_id = i?.type == "new" ? null : i?.object_id;
        if (i?.object_id || (!i?.object_id && !i?.is_delete))
          acc = [
            ...acc,
            {
              performer_deal_email_id: i?.object_id,
              email_type: i?.object_type,
              email: i?.value,
              is_delete: i?.is_delete || 0,
              is_primary: i?.is_primary,
            },
          ];
        return acc;
      }, []);
    }

    if (performerList.phone?.length > 0) {
      performerList.phone = performerList.phone?.reduce((acc, i) => {
        i.object_id = i?.type == "new" ? null : i?.object_id;
        if (i?.object_id || (!i?.object_id && !i?.is_delete))
          acc = [
            ...acc,
            {
              performer_deal_phone_id: i?.object_id,
              phone_type: i?.object_type,
              phone: i?.value,
              is_delete: i?.is_delete || 0,
              is_primary: i?.is_primary,
              ext: i?.ext ? parseInt(i?.ext) : null,
            },
          ];
        return acc;
      }, []);
    }
    let guardianList = this.state.guardianList;
    guardianList = guardianList?.reduce((acc, item) => {
      item.performer_deal_guardians_id =
        item?.type == "new" ? null : item?.performer_deal_guardians_id;
      if (
        item?.performer_deal_guardians_id ||
        (!item?.performer_deal_guardians_id && !item?.is_delete)
      ) {
        item.guardian_email_list = item?.guardian_email_list?.reduce(
          (accEmail, i) => {
            i.object_id = i?.type == "new" ? null : i?.object_id;
            if (i?.object_id || (!i?.object_id && !i?.is_delete))
              accEmail = [
                ...accEmail,
                {
                  performer_deal_guardians_email_id: i?.object_id,
                  email_type: i?.object_type,
                  email: i?.value,
                  is_delete: i?.is_delete || 0,
                  is_primary: i?.is_primary,
                },
              ];

            return accEmail;
          },
          []
        );
        item.guardian_phone_list = item?.guardian_phone_list?.reduce(
          (accPhone, i) => {
            i.object_id = i?.type == "new" ? null : i?.object_id;
            if (i?.object_id || (!i?.object_id && !i?.is_delete))
              accPhone = [
                ...accPhone,
                {
                  performer_deal_guardians_phone_id: i?.object_id,
                  phone_type: i?.object_type,
                  phone: i?.value,
                  is_delete: i?.is_delete || 0,
                  is_primary: i?.is_primary,
                  ext: i?.ext ? parseInt(i?.ext) : null,
                },
              ];
            return accPhone;
          },
          []
        );
        acc = [...acc, item];
      }
      return acc;
    }, []);
    this.setState({
      performerList: performerList,
      guardianList: guardianList,
      openEditPerformerContactModal: false,
    }, () => {
      this.props?.closePerformerMinor()
    });
  };

  handleHideRepPopup = () => {
    this.setState({ showEditRepresentative: false });
  };

  handleHideSendPaymentsToPopup = () => {
    this.setState({ showSendPaymentsTo: false });
  };
  handleCloseRepresentativePopOver = () => {
    this.setState({
      setRepresentativePopOver: null,
      openRepresentativePopOver: false,
    });
  };
  updateRepId = (id) => {
    let representativeSelected = { ...this.state?.representativeSelected };
    representativeSelected["id"] = id;
    this.setState({ selectedRep:representativeSelected });
  };
  setObjectSelected(type, value) {
    value.optionType = "edit";
    this.onChange(value);
  }
  setNewObjectSelected(type, value) {
    value.optionType = "save";
    this.onChange(value);
  }

  functions = {    
    handleHideRepresentativePopup: () => {
      this.setState({ showRepresentativePopup: false });
    },  
  handleHideAgencyPopup: () => {
      this.setState({ showAgencyPopup: false });
  },
    handleShowAgencyPopup: (id = null, agency_name) => {
      let agency = {
        value: id,
        text: agency_name,
      };
      this.setState({ agencyId: id, agencySelected: agency }, () => {
        this.setState({ showAgencyPopup: true });
      });
    },
    // handleHideAgencyPopup:() => {
    //     this.setState({ showAgencyPopup: false})
    // }
    handleShowRepresentativePopup: (id = null, representative_name) => {
      let rep = {
        value: id,
        text: representative_name
      };
      this.setState({ repId: id, selectedRep: rep }, () => {
        this.setState({ showRepresentativeModal: true })
      })
    }
  };

  handleHideRepresentativePopup () {
    this.setState({ showRepresentativePopup: false })
  }

  handleHideAgencyPopup() {
    this.setState({ showAgencyPopup: false });
  }
  handleHideRepoPopup() {
    this.setState({ showRepresentativeModal: false });
  }

  handleHideRepForAgencyPopup() {
    this.setState({ showRepresentativeModal: false, selectedRep: {} });
  }

  elobrateNotes = () => {
    this.setState({ showNotes: !this.state.showNotes })
  }
  refreshData=()=>{
    this.getContactDetails("REPRESENTATIVE");
    this.getContactDetails("PERFORMER");
    this.getContactDetails("GUARDIANS");
    this.getContactDetails("PAYMENTSTO");
  }
  postsendPaymentsTo = (sendPaymentsTo) => {
    this.setState({ isPosting: true, postInitiated: false,deleteloading:true })
    CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeaturePerformerDeal', sendPaymentsTo)
        .then((response) => {
            if (response.data.error) {
                this.props?.notificationComponent(true, 'fail')
            } else {
                this.getContactDetails("PAYMENTSTO");
                this.props?.notificationComponent(true, 'success');
                this.setState({deleteSendPaymentsTo:false});
            }
            this.setState({ isPosting: false })
        }, (err) => {
            this.setState({ isPosting: false })
            this.props?.notificationComponent(true, 'fail')
        });
}
  handleDeletesendpaymentTo = () =>{ 
    let sendPaymentsTo = {};
    sendPaymentsTo.performer_deal_id=this.props?.performer_deal_id;
    sendPaymentsTo.project_id = this.props?.project_id;
    sendPaymentsTo.is_send_payment_to = 1;
    sendPaymentsTo.entity_id=this.state.sendToPaymentList[0].entity_id;
    sendPaymentsTo.entity_type =  null
    sendPaymentsTo.address_type =  null
    sendPaymentsTo.entity_full_address =  null
    sendPaymentsTo.entity_notes = null
    sendPaymentsTo.entity_address = null
    sendPaymentsTo.entity_state =  null
    sendPaymentsTo.entity_city = null
    sendPaymentsTo.entity_country = null
    sendPaymentsTo.entity_zip = null
    console.log("sendpayment",this.state.sendToPaymentList);
    this.postsendPaymentsTo(sendPaymentsTo) ;
  }
  render() {
    return (
      <div className="ContactsAndPaymentsContainer">
        <MDBRow>
          <MDBCol md="4">
            <h6 className="bold">Representation</h6>
          </MDBCol>
          <MDBCol md="1" lg="1" xl="2"></MDBCol>
          {!this.props?.summary && (
            <MDBCol
              md="7"
              lg="7"
              xl="6"
              className="bold addRepSection marginBottom"
            >
               {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
              <div className="repLabel">Add Representative</div>
               }
               
              <div className="repSelect">
              {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                <SearchFieldWithAddValue
                  id="repFieldContactAndPay"
                  value={this.state?.representativeSelected}
                  newLabel={"Representative"}
                  onChange={(e, value) => this.onChange(value, e)}
                  searchSelect={true}
                  detail_selected={this.state?.representativeSelected}
                  placeholder="-Search by Alias or Company -"
                  loading={this.state.isRepresentativeFetch}
                  searchText={(ev) => {
                    if (ev.target.value !== "" && ev.target.value !== null) {
                      this.getObjectDetails("Representative", ev.target.value);
                    } else {
                      this.setState({ isRepresentativeFetch: false });
                    }
                  }}
                  options={this.state?.representativeOptions || []}
                />}

                {this.state?.openRepresentativePopOver ? (
                  <NewRepresentative
                    id={"NewRepPerformer"}
                    open={this.state.openRepresentativePopOver}
                    setPopOver={this.state?.setRepresentativePopOver}
                    handleClose={this.handleCloseRepresentativePopOver.bind(
                      this
                    )}
                    representativeData={this.state?.selectedRep}
                    updateRepId={this.updateRepId.bind(this)}
                    setObjectSelected={this.setObjectSelected.bind(this)}
                    setNewObjectSelected={this.setNewObjectSelected.bind(this)}
                  />
                ) : null}
              </div>
              {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" && 
              (this.state?.addLoader ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                <BasicButton
                  icon="plus-circle"
                  onClick={() => this.addRepresentative()}
                />
              ))}
            </MDBCol>
          )}
        </MDBRow>
        <div className="contactAndPaymentList">
          {this.state?.isDefaultPost || this.state.isPERFORMER || this.state.isREPRESENTATIVE? (
            <div className="ContentLoader">
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              {/* Representative section */}
              <div className="representativeSection">
                {this.state?.representativeList?.length == 0 ? (
                  <div> </div>
                ) : null}
                {this.state?.representativeList?.map((item, index) => (
                  <MDBRow className="representativetableRow marginBottom striped">
                    <>
                    {/* <MDBCol md="1" className="combineLabelContainer">
                                            {!this.props?.summary && <>

                                                <BasicCheckbox
                                                    id={index + "-representative"}
                                                    label="Primary Rep"
                                                    checked={item.is_primary === 1}
                                                    // align="start"
                                                    onChange={(e) =>
                                                        this.onCheckboxChange(
                                                            item?.performer_deal_representative_id,
                                                            "is_primary",
                                                            e.target?.checked,
                                                            "REPRESENTATIVE"
                                                        )
                                                    }
                                                />
                                                <input
                                                    id={`radioAddress_${item?.performer_deal_representative_id}`}
                                                    name="contactsAndPaymentRadio"
                                                    size="small"
                                                    type="radio"
                                                    className="contactsAndPaymentRadio"
                                                    // value={item?.talent_guardians_id}
                                                    // defaultChecked={item?.is_primary == 1 ? true : false}
                                                    checked={item?.is_send_to_contract === 1}
                                                    onChange={(e) =>
                                                        this.onRadioChange(
                                                            item?.performer_deal_representative_id,
                                                            "is_send_to_contract",
                                                            e.target.checked === true ? 1 : 0,
                                                            "REPRESENTATIVE"
                                                        )
                                                    }
                                                />
                                            </>}
                                        </MDBCol> */}
                                        </>
                    <MDBCol md="3">
                      <div className={`bold smallText`}>
                        <span>
                          <a
                            onClick={() => this.functions?.handleShowRepresentativePopup(item?.representative_id, item?.selected_alias_name)}>
                            {item?.selected_alias_name}
                          </a>
                        </span> | <span>{item?.representative_occupation}</span>
                      </div>
                      {!this.props?.summary && (
                        <>

                          <div>
                            <input
                              id={`radioAddress_${item?.performer_deal_representative_id}`}
                              name="contactsAndPaymentRadio"
                              size="small"
                              type="radio"
                              className="contactsAndPaymentRadio"
                              // value={item?.talent_guardians_id}
                              // defaultChecked={item?.is_primary == 1 ? true : false}
                              checked={item?.is_send_to_contract === 1}
                              onChange={(e) =>
                                this.onRadioChange(
                                  item?.performer_deal_representative_id,
                                  "is_send_to_contract",
                                  e.target.checked === true ? 1 : 0,
                                  "REPRESENTATIVE"
                                )
                              }
                            />
                            <label for={`radioAddress_${item?.performer_deal_representative_id}`}>Use for Contract Address</label>
                          </div>
                          <BasicCheckbox className={'noMargin'}
                            id={index + "-representative"}
                            label="Primary Rep"
                            checked={item.is_primary === 1}
                            // align="start"
                            onChange={(e) =>
                              this.onCheckboxChange(
                                item?.performer_deal_representative_id,
                                "is_primary",
                                e.target?.checked,
                                "REPRESENTATIVE"
                              )
                            }
                          />
                        </>
                      )}
                      {/* <div className="smallText combineLabelContainer">
                                                <div className="">{item?.occupation}</div>                                                
                                            </div> */}
                    </MDBCol>
                    <MDBCol md="2">
                      <div className={`smallText marginBottom`}>
                        <a
                          onClick={() =>
                            this.functions?.handleShowAgencyPopup(
                              item?.agency_id,
                              item?.agency_name,
                              "fromCompanyEdit"
                            )
                          }
                        >
                          {item?.agency_name}
                        </a>
                        {/* {item?.location ? 
                                           
                                            <Tooltip clickable 
                                            title={
                                            <>
                                                {item?.agency_phone?.map(o=>(
                                                    <div className="combineLabelContainer">
                                                        <span>{o?.phone_type}</span>&nbsp;: &nbsp;
                                                        <span>{o?.phone}</span>&nbsp; 
                                                        <span>{o?.ext ? `ext.${o?.ext}`:''}</span> 
                                                    </div>
                                                
                                                ))}
                                            </>
                                            }>
                                                   
                                                   <span className="repTool">
                                                    <MDBIcon icon="phone"/>
                                                   </span>
                                                   
                                            
                                             </Tooltip>
                                             : null
                                              } */}
                      </div>
                      <div className="smallText representativeSec">  {this.getFullAddress(item?.agency_address, "representative")}</div>
                    </MDBCol>
                    <MDBCol md="3" className="emailContainer">
                      {item?.email?.map((obj) => (
                        <div className={`smallText combineLabelContainer`}>
                          <div>{obj?.email_type}</div>
                          <div
                            className={`${obj?.is_primary == 1 ? "primaryStarIcon" : ""
                              }`}
                          >
                            {obj?.email}
                          </div>
                        </div>
                      ))}
                    </MDBCol>
                    <MDBCol md="3" className="phoneContainer">
                      {item?.phone?.map((obj) => (
                        <div className={`smallText combineLabelContainer`}>
                          <div className="">{obj?.phone_type}</div>
                          <div
                            className={`${obj?.is_primary == 1 ? "primaryStarIcon" : ""
                              }`}
                          >
                            {obj?.phone} {obj?.ext ? `ext.${obj?.ext}` : ""}{" "}
                          </div>
                        </div>
                      ))}
                    </MDBCol>
                    <MDBCol md="1" className="iconContainer">
                      {!this.props?.summary && (
                        <>
                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" && 
                          <MDBIcon
                            icon="pen"
                            onClick={() =>
                              this.openRepresentative(
                                item?.performer_deal_id,
                                item?.performer_deal_representative_id
                              )
                            }
                          />}
                          {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" && 
                          <MDBIcon
                            icon="minus-circle"
                            className="color"
                            onClick={() =>
                              this.handleShowHideDeletePopup(true, {
                                id: item?.performer_deal_representative_id,
                                representative_id: item?.representative_id,
                              })
                            }
                          />}
                        </>
                      )}
                    </MDBCol>
                  </MDBRow>
                ))}
              </div>
              {/* performer section */}
              <MDBRow className="">
                <MDBCol md={10}>
                  <div className={`bold smallText`}>Direct Contact</div>
                </MDBCol>
                {!this.props?.summary && (
                  <MDBCol
                    md={2}
                    className="marginBottom d-flex justify-content-end"
                  >
                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" && 
                    <MDBIcon
                      icon="pen"
                      className="primaryColorText"
                      onClick={() => this.openEditPerformerContacts()}
                    />}
                  </MDBCol>
                )}
              </MDBRow>
              <div className="performerGuardianSection marginBottom">
                <MDBRow className="striped">
                  <MDBCol md="3" className="">
                    {!this.props?.summary && (
                      <>
                        <div>
                          <input
                            id={`radioAddress_${this.state?.performerList?.performer_deal_id}`}
                            name="contactsAndPaymentRadio"
                            size="small"
                            type="radio"
                            className="contactsAndPaymentRadio"
                            // value={item?.talent_guardians_id}
                            // defaultChecked={item?.is_primary == 1 ? true : false}
                            checked={
                              this.state?.performerList
                                ?.is_send_to_contract === 1
                            }
                            onChange={(e) =>
                              this.onRadioChange(
                                this.state?.performerList?.performer_deal_id,
                                "is_send_to_contract",
                                e.target.checked === true ? 1 : 0,
                                "PERFORMER"
                              )
                            }
                          />
                          <label for={`radioAddress_${this.state?.performerList?.performer_deal_id}`}>Use for Contract Address</label>
                        </div>
                        <BasicCheckbox
                          id={"performerChb1"}
                          label="No Phone on Contract"
                          checked={
                            this.state?.performerList?.no_phone_on_contract === 1
                          }
                          onChange={(e) =>
                            this.onCheckboxChange(
                              this.state?.performerList?.performer_deal_id,
                              "no_phone_on_contract",
                              e.target?.checked,
                              "PERFORMER"
                            )
                          }
                        />
                        <BasicCheckbox
                          id={"performerchb2"}
                          label="No Address on Contract"
                          checked={
                            this.state?.performerList?.no_address_on_contract === 1
                          }
                          onChange={(e) =>
                            this.onCheckboxChange(
                              this.state?.performerList?.performer_deal_id,
                              "no_address_on_contract",
                              e.target?.checked,
                              "PERFORMER"
                            )
                          }
                        />

                      </>
                    )}
                  </MDBCol>
                  {/* <MDBCol md="2"> */}
                  {/* <div className={`bold smallText`}>Performer</div> */}
                  {/* </MDBCol> */}
                  <MDBCol md="2" className="contactAddressInfo">
                    {this.state?.performerList?.address
                      ?.filter((i) => i?.is_default == 1 || i?.is_primary == 1)
                      ?.map((j) => (
                        <>
                          <div className={`smallText marginBottom`}>
                            {j?.address_type}
                          </div>
                          <div
                            className={`${j?.is_primary == 1
                              ? "primaryStarIcon smallText"
                              : "smallText"
                              }`}
                          >
                            {this.getFullAddress(j, "performer")}
                          </div>
                        </>
                      ))}
                  </MDBCol>

                  <MDBCol md="3" className="emailContainer">
                    {this.state?.performerList?.email?.map((obj) => (
                      <div className={`smallText combineLabelContainer`}>
                        <div className="">{obj?.email_type}</div>
                        <div
                          className={`${obj?.is_primary == 1 ? "primaryStarIcon" : ""
                            }`}
                        >
                          {obj?.email}
                        </div>
                      </div>
                    ))}
                  </MDBCol>
                  <MDBCol md="3" className="phoneContainer">
                    {this.state?.performerList?.phone?.map((obj) => (
                      <div className={`smallText combineLabelContainer`}>
                        <div className="">{obj?.phone_type}</div>
                        <div
                          className={`${obj?.is_primary == 1 ? "primaryStarIcon" : ""
                            }`}
                        >
                          {obj?.phone} {obj?.ext ? `ext.${obj?.ext}` : ""}
                        </div>
                      </div>
                    ))}
                  </MDBCol>
                  <MDBCol md="1"></MDBCol>
                </MDBRow>

                {/* Guardian list */}
                {(this.props?.age === 0 && this.props?.monthsAge) || this.props?.age < 18 ? this.state?.guardianList?.map((item) => (
                  <MDBRow className="guradianRow striped">
                    {/* <MDBCol md="1" className="combineLabelContainer"></MDBCol> */}
                    <MDBCol md="3">
                      <div
                        className={`bold smallText ${item?.is_primary == 1 ? "primaryStarIcon" : ""
                          }`}
                      >
                        {item?.selected_alias_name}
                      </div>
                      <div className="smallText combineLabelContainer">
                        <div className="rightBorder">Guardian</div>
                        <div>{item?.relation}</div>
                      </div>
                    </MDBCol>
                    <MDBCol md="2">
                      <div className={`smallText marginBottom`}>
                        {item?.guardian_address_type}
                      </div>
                      <div className="smallText">
                        {this.getFullAddress(item, "guardian")}
                      </div>
                    </MDBCol>

                    <MDBCol md="3" className="emailContainer">
                      {item?.guardian_email_list?.map((obj) => (
                        <div className={`smallText combineLabelContainer`}>
                          <div className="">{obj?.email_type}</div>
                          <div
                            className={`${obj?.is_primary == 1 ? "primaryStarIcon" : ""
                              }`}
                          >
                            {obj?.email}
                          </div>
                        </div>
                      ))}
                    </MDBCol>
                    <MDBCol md="3" className="phoneContainer">
                      {item?.guardian_phone_list?.map((obj) => (
                        <div className={`smallText combineLabelContainer`}>
                          <div className="">{obj?.phone_type}</div>
                          <div
                            className={`${obj?.is_primary == 1 ? "primaryStarIcon" : ""
                              }`}
                          >
                            {obj?.phone} {obj?.ext ? `ext.${obj?.ext}` : ""}
                          </div>
                        </div>
                      ))}
                    </MDBCol>
                    <MDBCol md="1"></MDBCol>
                  </MDBRow>
                )): ""}
              </div>
              <MDBRow className="marginBottom">
                <MDBCol md={10}>
                  <h6 className="">Send Payments To</h6>
                </MDBCol>
                <MDBCol md={2} className="iconContainer">
                {!this.props?.summary && (
                  <>
                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                      <MDBIcon
                        icon="pen"
                        className="primaryColorText"
                        onClick={() => this.setState({ showSendPaymentsTo: true })}
                      />
                    }
                    {this.state?.sendToPaymentList?.length != 0 && this.props?.userContext?.user_profile?.user_role_name !== "Read Only" ? 
                      <MDBIcon
                            icon="minus-circle"
                            className="color"
                            onClick={()=>this.showDeleteSendPaymentToModal()} 
                          />
                        :"" }
                  </>
                )}
              </MDBCol>
              </MDBRow>
              {this.state?.sendToPaymentList?.length == 0 ? (
                <MDBRow className="sendPaymentRow striped">
                  <MDBCol md="12">No Results</MDBCol>
                </MDBRow>
              ) : null}
              {this.state?.sendToPaymentList?.map((item) => (
                <MDBRow className="sendPaymentRow striped">
                  <MDBCol md="1" className="combineLabelContainer"></MDBCol>
                  <MDBCol md="2">
                    <div className={`bold smallText`}>
                      {item?.selected_alias_name}
                    </div>
                    <div className="smallText combineLabelContainer">
                      <div className="rightBorder">{item?.occupation}</div>
                      <a
                        onClick={() =>
                          this.functions?.handleShowAgencyPopup(
                            item?.agency_id,
                            item?.agency_name
                          )
                        }
                      >
                        {item?.agency_name}
                      </a>
                    </div>
                  </MDBCol>
                  <MDBCol md="4">
                    <div className={`smallText marginBottom`}>{item?.address_type}</div>
                    <div className="smallText sendPaymentsAddress">{this.getFullAddress(item?.full_address, "send_payments_to")}</div>
                  </MDBCol>

                  <MDBCol md="4" className="">
                    <div className={this.state.showNotes ? `smallText` : `smallText notes-multiline-ellipses`} onClick={() => this.elobrateNotes()}>{item?.entity_notes}</div>
                  </MDBCol>

                  <MDBCol md="1"></MDBCol>
                </MDBRow>
              ))}
            </>
          )}
        </div>
        <MessageModal
          className="deleteRep"
          title={"Delete Confirmation"}
          open={this.state?.showDeleteConfirmPopup}
          handleClose={this.handleShowHideDeletePopup.bind(this, false)}
        >
          <p className="delete-modal-title">
            Would you like remove this Representative from{" "}
            <b>this talent record only</b>, or &nbsp;
            <b>permanently disassociate</b>?
          </p>
          {/* <div className="ButtonDiv"> */}
          <MDBRow className="delRepBtnRow">
            <MDBCol className="flex justify-content-between">
              <BasicButton
                variant="outlined"
                type="inline"
                text={
                  this.state?.isDeleteRep == "record" ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : (
                    "This Record Only"
                  )
                }
                onClick={this.deleteConfirmation.bind(this, "record")}
              />
              {/* </MDBCol>
                        <MDBCol md={5}> */}
              <BasicButton
                variant="outlined"
                type="inline"
                text={
                  this.state?.isDeleteRep === "permanent" ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : (
                    "Permanently Disassociate"
                  )
                }
                className="redTextBorder"
                onClick={this.deleteConfirmation.bind(this, "permanent")}
              />
              {/* </MDBCol>
                        <MDBCol md={3}> */}
              <BasicButton
                variant="outlined"
                type="inline"
                text={"Cancel"}
                icon={"times"}
                onClick={this.handleShowHideDeletePopup.bind(this, false)}
                disabled={false}
              />
            </MDBCol>
          </MDBRow>

          {/* <div className="FloatRight">
                            
                        </div>
                    </div> */}
        </MessageModal>
        {this.state.showRepresentativeModal ? (
          <ModalComponent
            id={this.state.selectedRep?.value?this.state.selectedRep?.value:this.state.selectedRep?.representative_id}
            open={this.state.showRepresentativeModal}
            handleClose={this.handleHideRepForAgencyPopup.bind(this)}
            tabList={representativeFCNavList}
            headerText={"Representative"}
            mode="edit"
            isProfileDiv={true}
            addButtonText={"New Representative"}
            objectType={"REPRESENTATIVE"}
            objectId={this.state.selectedRep?.value?this.state.selectedRep?.value:this.state.selectedRep?.representative_id}
            akas={
              this.state.selectedRep?.aka
                ? this.state.selectedRep?.aka
                : ""
            }
            headerName={this.state.selectedRep?.text?this.state.selectedRep?.text:this.state.selectedRep?.representative_name}
            navFuns={this.functions}
            handleHideRepoPopup={this.handleHideRepoPopup.bind(this)}
          />
        ) : null}
        {this.state.showAgencyPopup ? (
          <ModalComponent
            id={this.state.agencySelected?.value}
            agencyId={this.state?.agencySelected}
            open={this.state.showAgencyPopup}
            handleClose={this.handleHideAgencyPopup.bind(this)}
            tabList={agencyNavList}
            headerName={this.state?.agencySelected?.text}
            headerText={"Agency"}
            mode="edit"
            isProfileDiv={true}
            addButtonText={"New Agency"}
            objectType={"AGENCY"}
            akas={this.state.agencySelected?.aka}
            navFuns={this.functions}
            dataUrl="casterFeatureAgencyDetails"
            selectedRep={this.state?.selectedRep}
            handleShowRepresentativePopup={this.functions?.handleShowRepresentativePopup.bind(this)}
            handleShowAgencyPopup={this.functions?.handleShowAgencyPopup.bind(this)}
            handleHideRepoPopup={this.handleHideRepoPopup.bind(this)}
          />
        ) : null}
        {this.state.showRepresentativePopup ? (
          <ModalComponent
            id={this.state.selectedRep?.value ? this.state.selectedRep?.value : this.state.selectedRep?.representative_id}
            objectId={this.state.selectedRep?.representative_id ? this.state.selectedRep?.representative_id:this.state?.repId}
            open={this.state.showRepresentativePopup}
            handleClose={this.handleHideRepresentativePopup.bind(this)}
            tabList={representativeFCNavList}
            headerName={this.state?.selectedRep?.representative_name?this.state?.selectedRep?.representative_name:this.state?.selectedRep?.text}
            headerText={"Representative"}
            mode="edit"
            isProfileDiv={true}
            addButtonText={"New Representative"}
            objectType={"REPRESENTATIVE"}
            akas={this.state.selectedRep?.aka}
            navFuns={this.functions}
            dataUrl="casterFeatureRepresentativeDetails"
          />
        ) : null}
        {this.state.showEditRepresentative ? (
          <Modal
            open={this.state.showEditRepresentative}
            handleClose={this.handleHideRepPopup.bind(this)}
            modalName={"Edit Representative"}
            headerText={"Edit Representative"}
            mode="Both"
            repId={this.state.repId}
            dealId={this.state.dealId}
            toggleRefreshPage={this.props?.toggleRefreshPage}
          />
        ) : null}
        {this.state.openEditPerformerContactModal ? (
          <Modal
            performerList={this.state?.performerList}
            guardianList={this.state?.guardianList}
            talent_name={this.props?.talent_name}
            open={this.state.openEditPerformerContactModal}
            modalName={"Edit Performer Contact"}
            headerText={"Edit Performer Contact"}
            mode="Both"
            getPeformerDetailsFlags={() => {
              this.props?.getPeformerDetailsFlags(this.props?.performer_deal_id)
            }}
            age={this?.props?.age}
            monthsAge={this.props?.monthsAge}
            birth_date={this.props?.birth_date}
            getTalentPerformerDetails={() =>
              this.props?.getTalentPerformerDetails(
                this.props?.performer_deal_id
              )
            }
            handleClose={() => this.handleClose()}
            getContactDetails={() => this.getContactDetails("PERFORMER")}
            getGuardianDetails={() => this.getContactDetails("GUARDIANS")}
          />
        ) : null}
        {this.state.showSendPaymentsTo ? (
          <Modal
            open={this.state.showSendPaymentsTo}
            handleClose={this.handleHideSendPaymentsToPopup.bind(this)}
            modalName={"Send Payments To"}
            headerText={"Send Payments To"}
            mode="Edit"
            deal_id={this.props?.performer_deal_id}
            project_id={this.props?.project_id}
            sendToPaymentList={this.state.sendToPaymentList}
            toggleRefreshPage={() => this.getContactDetails("PAYMENTSTO")}
          // refreshPage={this.props?.refreshPage}
          // isProfileDiv={true}
          // addButtonText={"New Crew"}
          // objectType={'CREW'}
          // objectId={this.state?.selectedCrew?.value || 1}
          // dataUrl='casterFeatureCrewDetails'
          // headerName={this.state.selectedCrew?.headerName}
          />
        ) : null}
        {
                    this.state?.deleteSendPaymentsTo &&
                    <MessageModal
                        title={"Delete Confirmation"}
                        open={this.state?.deleteSendPaymentsTo}
                        message={" Would you like to delete the Send Payment To ?"}
                        primaryButtonText={this.state?.deleteloading ? <CircularProgress color="inherit" size={15} />:"Ok"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => this.handleDeletesendpaymentTo()}
                        handleClose={(e) => this.setState({ deleteSendPaymentsTo: false })}
                    />
                    
                }
      </div>
    );
  }
}
export default withUserContext(ContactsAndPayments);

import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import { withRouter } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@material-ui/core/Chip'
import Popover from '@mui/material/Popover';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import DateField from '../SharedComponents/DateField/DateField';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import SelectField from '../SharedComponents/SelectField/SelectField';
import Modal from '../../Common/MainViewModalLayout/Modal/Modal';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal'
import { talentFCNavList } from '../Talent/Config'
import { agencyNavList } from '../../../constants/NavList'
import { crewNavList } from '../Crew/Config'
import { representativeFCNavList } from '../Representative/config'
import { withUserContext } from '../../../contexts/UserContext.js';
import TableComponent from '../SharedComponents/Table';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import "./AdvanceSearch.scss";
import '../Representative/Representative.scss';
import NewRepresentative from "../../Common/NewRepresentative/NewRepresentative";
import NewTalent from '../../Common/NewTalent/NewTalent';
import NewCrewModal from '../../Common/NewCrew/NewCrew';
import NewAgency from '../../Common/NewAgency/NewAgency';
import { getFormattedDate } from '../../Common/Helper';
import { entityList, filterTypeDateOptions, allCriteriaConfig, nullCheckValues, tableConfigs, adsInitialConfig } from './Config.js'
import ReactHtmlParser from 'react-html-parser';

class AdvanceSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entityList: JSON.parse(JSON.stringify(entityList)),
            dataBaseMapping: [],
            selectedFilters: [],
            list: [],
            page: 0,
            rowsPerPage: 10,
            count: 0,
            config: JSON.parse(JSON.stringify(adsInitialConfig)),
            availableColumns: [],
            entity: null,
            selectedSavedSearch: null,
            allSavedSearch: [],
            fromQuery: null,
            whereQuery: null,
            tempWhereQuery: null,
            orderByClause: null,
            openDataMaintenance: null,
            savedSearch: null,
            personType: null,
            showNewProduction: null,
            showAgencyPopup: null,
            showRepresentativeModal: false,
            openRepresentativePopOver: null,
            repSelected: null,
            showTalentPopup: null,
            personTypeOptions: [{ value: 'Performer', label: 'Performer' }, { value: 'Guardian', label: 'Guardian' }, { value: 'Representative', label: 'Representative' }, { value: 'Crew', label: 'Crew' }],
            daysWeeks: [{ value: 'days', label: 'Days' }, { value: 'weeks', label: 'Weeks' }],
            signTypes: [{ value: '+', label: '+' }, { value: '-', label: '-' }],
            voidDetails: [{ value: '0', label: 'No' }, { value: '1', label: 'Yes' }],
            givenCreditsDetails: [{ value: 'null', text: 'None' }, { value: "'MT_ET'", text: 'Main Title/End Title' }, { value: "'ET'", text: 'End Title' }, { value: "'PA'", text: 'Paid Ads' }, { value: 'not null', text: 'Any' }],
            confirmCreditsDetails: [{ value: 'null', text: 'None' }, { value: 'MT', text: 'Main Title/End Title' }, { value: 'ET', text: 'End Title' }, { value: 'PA', text: 'Paid Ads' }, , { value: 'CUT', text: 'Cut' }, { value: 'NC', text: 'NC' }, { value: 'not null', text: 'Any' }]
        }
    }

    componentDidMount() {
        this.onChangeHeaderOptionsCallBack('entity', 'Performers')
        this.getLookUpValue('State', 'stateDetails');
        this.getSearchOptions("PERFORMER_TYPE", null, 'performerTypeDetails', 'performer_type', 'performer_type', true)
        this.getSearchOptions("FEE_TYPE", null, 'feeTypeDetails', 'fee_type', 'fee_type', true)
        this.getSearchOptions("WORK_ACTIVITY", null, 'activityTypeDetails', 'work_activity_code', 'work_activity_code')
        this.getSavedSearch()
        this.getUserDetails()

    }
    getProjectDetails = (project_id) => {
        this.setState({ fetchingProjectDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/casterFeatureProject?project_id=${project_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response?.data?.error || !response?.data) {
                    this.setState({ fetchingProjectDetails: false })
                }
                else {
                    let formattedList = response?.data?.[0]?.production_companies?.map(item => ({ value: item?.production_company, text: item?.production_company }))
                    this.setState({ projectProdCompanies: formattedList }, () => { console.log('podcompanies', this.state.projectProdCompanies) })
                    console.log(response?.data);
                }

            }, err => {
                this.setState({ fetchingProjectDetails: false })
                console.log("error from project details")
            })

    }

    handleNavigation = (item, node) => {
        if (node === 'project_name') {
            //this.setState({ showNewProduction: item?.project_id })
            this.props.history.push('/caster-feature/project/' + item?.project_id)
        }
        if (node === 'talent_name') {
            this.setState({ showTalentPopup: item })
        }
        if (node === 'representative_name') {
            this.setState({
                showRepresentativeModal: true, repSelected: {
                    headerName: item?.representative_name || null,
                    value: item?.representative_id || null,
                    aka: item?.aka || null
                }
            })
        }
        if (node === 'crew_name') {
            this.setState({ showCrewPopup: item })
        }
        if (node === 'agency_name') {
            this.setState({ showAgencyPopup: item })
        }
    }

    getUserDetails = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureGetUser`)
            .then(response => {
                let list = []
                list.push({ value: 'null', text: 'None' })
                list.push({ value: 'not null', text: 'Any' })
                for (let i = 0; i < response.data.length; i++) {
                    list.push({ value: response.data[i]?.user_id, text: response.data[i]?.full_name })
                }
                this.setState({ assignedToDetails: list })
            }, (err) => {
                console.log("Error in fetching lookup:", err)
            })
    }

    getSavedSearch = (id = null) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSaveAdvanceSearch`)
            .then(response => {
                this.setState({ allSavedSearch: response?.data, isLoadingSets: false, selectedSavedSearch: null, }, () => {
                    let selectedSavedSearch = response?.data?.find(item => item?.advance_search_query_id === id)
                    if (id) {
                        if (response?.data?.length === 0 || !selectedSavedSearch) {
                            this.setState({
                                list: [],
                                selectedFilters: [],
                                whereQuery: null,
                                fromQuery: this.getFromQueryForSort(),
                                tempWhereQuery: null,
                                page: 0,
                                rowsPerPage: 10,
                                count: 0,
                                selectedSavedSearch: null
                            })
                        } else {
                            this.setState({ selectedSavedSearch: selectedSavedSearch }, () => {
                                this.arrangeSavedQueryData(this.state.selectedSavedSearch)
                            })
                        }
                    }
                })
            },
                (err) => {
                })
    }

    arrangeSavedQueryData = (query, callSearch = false) => {
        console.log("query in arrangeSavedQueryData",query);
        let criteria = JSON.parse(query.query)
        let entityName = query?.entity?.charAt(0) + query?.entity?.slice(1)?.toLowerCase()
        if (this.state?.entity !== entityName) {
            this.setState({
                availableColumns: JSON.parse(JSON.stringify(tableConfigs[entityName])), entity: entityName,
                selectedGroups: allCriteriaConfig?.find(item => item?.value === entityName)?.filters
            })
        }
        this.setState({
            fromQuery: criteria?.from_query,
            whereQuery: criteria?.where_query,
            tempWhereQuery: criteria?.temp_where_query,
            orderByClause: criteria?.order_by_clause,
            personType: criteria?.person_type || '',
            page: 0,
            rowsPerPage: 10,
            config: JSON.parse(query?.config),
            selectedFilters: JSON.parse(query?.ui_query)
        }, () => {
            if (callSearch) {
                this.getAdvanceSearchData()
            }
        })
    }

    postSavedSearch = (id, name, isDelete) => {
        let postJson = {
            "advance_search_query_id": id,
            "name": name,
            "config": JSON.stringify(this.state.config),
            "entity": this.state.entity?.toUpperCase(),
            "query": JSON.stringify({
                "where_query": this.state.whereQuery,
                "temp_where_query": this.state.tempWhereQuery,
                "from_query": this.state.fromQuery,
                "order_by_clause": this.state.orderByClause,
                "person_type": this.state.personType?.toUpperCase()
            }),
            "is_delete": isDelete,
            "ui_query": JSON.stringify(this.state.selectedFilters),
        }
        this.setState({ isLoadingSets: true, savedSearchPopver: null })
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureSaveAdvanceSearch`, postJson)
            .then(response => {
                this.getSavedSearch(response?.data[0]?.advance_search_query_id)
            },
                (err) => {
                    console.log('error saving the search')
                })
    }


    getDatabaseMapping = (entity) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=ADVANCE_SEARCH&searchString=${entity}`)
            .then(response => {
                this.setState({ dataBaseMapping: response.data })
            },
                (err) => {
                })
    }


    onChangeHeaderOptionsCallBack = (field, value) => {
        this.getDatabaseMapping(value)
        let availableColumns = JSON.parse(JSON.stringify([...tableConfigs[value]]))
        let config = this.getTabelConfig(availableColumns)
        config.headings[0].icon = 'sort-alpha-up'
        this.setState({
            entity: value,
            selectedFilters: [],
            list: [],
            whereQuery: null,
            count: 0,
            tempWhereQuery: null,
            page: 0,
            config: config,
            orderByClause: 'ORDER BY ' + config.dataNodes[0] + ' ASC',
            rowsPerPage: 10,
            availableColumns: availableColumns,
            selectedGroups: allCriteriaConfig?.find(item => item?.value === value)?.filters,
            personType: value === 'People' ? 'Performer' : null,
            selectedSavedSearch: null,
        }, () => {
            this.setState({ fromQuery: this.getFromQueryForSort() })
        })
    }

    getTabelConfig = (availableColumns) => {
        let actualConfig = {
            headings: [], dataNodes: [], hyperlinks: [],
        }
        let orderByStr = this.state.orderByClause ? this.state.orderByClause?.split(' ') : null
        for (let i = 0; i < availableColumns?.length; i++) {
            if (availableColumns[i]?.isChecked === 1) {
                actualConfig.headings.push({
                    headingLabel: availableColumns[i]?.text,
                    icon: orderByStr ? orderByStr[2] === availableColumns[i]?.value ? orderByStr[3] === 'ASC' ? "sort-alpha-up" : "sort-alpha-down" : '' : '',
                    width: "25%",
                })
                actualConfig.dataNodes.push(availableColumns[i]?.value)
                if (availableColumns[i]?.hyperLink) {
                    actualConfig.hyperlinks.push(availableColumns[i]?.value)
                }
            }
        }
        return actualConfig
    }

    getFromQueryForSort = () => {
        let orderByStr = this.state.orderByClause ? this.state.orderByClause?.split(' ') : null
        let fromQuery = {}
        if (this.state.entity !== 'People') {
            if (orderByStr) {
                fromQuery[this.state.availableColumns?.find(item => item?.value === orderByStr[2])?.tableName] = 1
            }
            for (let i = 0; i < this.state.availableColumns?.length; i++) {
                if (this.state.availableColumns[i]?.isChecked === 1) {
                    fromQuery[this.state.availableColumns[i]?.tableName] = 1
                }
            }
        }
        return orderByStr && this.state.entity !== 'People' ? fromQuery : null
    }

    handleFilterDelete = (criteria, criteriaIndex) => {
        let selectedFilters = JSON.parse(JSON.stringify(this.state.selectedFilters))
        if (selectedFilters.length === 1) {
            this.setState({ selectedFilters: [], fromQuery: this.getFromQueryForSort(), whereQuery: null, list: [], page: 0, rowsPerPage: 10, count: 0, tempWhereQuery: null })
        } else {
            selectedFilters.splice(criteriaIndex, 1)
            this.generateQueryFields(selectedFilters)
            this.setState({ selectedFilters: selectedFilters })
        }
    }

    generateApiValue = (fieldType, entertedValue, fieldText) => {
        if (fieldType === 'text' || fieldType === 'Select' || fieldType === 'State' || fieldType === 'date' || fieldType === 'textArea') {
            return entertedValue ? (entertedValue === 'null' || entertedValue === 'None') ? `is null` : (entertedValue === 'not null' || entertedValue === 'Any') ? 'is not null' : ` LIKE '%${entertedValue}%' ` : `is null`
        } else if (fieldType === 'SearchSelect' || fieldType === 'Search') {
            return entertedValue ? (entertedValue?.value2 === 'null' || entertedValue?.value1 === 'None') ? `is null` : (entertedValue?.value2 === 'not null' || entertedValue?.value1 === 'Any') ? 'is not null' : fieldText === 'Confirmed Credit' ? ` LIKE '%${entertedValue?.value2}%' ` : ` = ${entertedValue?.value2} ` : `is null`
        } else {
            if (fieldType === 'dateRange') {
                let tempVal1 = entertedValue?.value1 ? '"' + entertedValue?.value1 + '"' : null
                let tempVal2 = entertedValue?.value2 ? '"' + entertedValue?.value2 + '"' : null
                return entertedValue?.compValue === 'between' ? ` BETWEEN ${tempVal1} AND ${tempVal2} ` : entertedValue?.compValue === '>' ? ` > ${tempVal1} ` : entertedValue?.compValue === '<' ? ` < ${tempVal2} ` : entertedValue?.compValue === 'not null' ? ` is not null ` : ` is null `
            } else {
                return entertedValue?.compValue === 'between' ? ` BETWEEN ${entertedValue?.value1} AND ${entertedValue?.value2} ` : entertedValue?.compValue === '>' ? ` > ${entertedValue?.value1} ` : entertedValue?.compValue === '<' ? ` < ${entertedValue?.value2} ` : entertedValue?.compValue === 'not null' ? ` is not null ` : ` is null `
            }
        }
    }

    generateUIValue = (fieldType, entertedValue) => {
        if (fieldType === 'text' || fieldType === 'Select' || fieldType === 'State' || fieldType === 'date' || fieldType === 'textArea') {
            return entertedValue ? (entertedValue === 'null' || entertedValue === 'None') ? `None` : (entertedValue === 'not null' || entertedValue === 'Any') ? 'Any' : fieldType === 'date' ? `${getFormattedDate(entertedValue)}` : `Contains '${entertedValue}'` : `None`
        } else if (fieldType === 'SearchSelect' || fieldType === 'Search') {
            return entertedValue ? (entertedValue?.value2 === 'null' || entertedValue?.value1 === 'None') ? `None` : (entertedValue?.value2 === 'not null' || entertedValue?.value1 === 'Any') ? 'Any' : `${entertedValue?.value1}` : `None`
        } else {
            if (entertedValue?.hasOwnProperty('typeDropDown') && entertedValue?.hasOwnProperty('signType')) {
                return entertedValue?.compValue === 'between' ? `BETWEEN ${entertedValue?.value1} ${entertedValue?.typeDropDown} AND ${entertedValue?.value2} ${entertedValue?.typeDropDown}` : entertedValue?.compValue === '>' ? ` > ${entertedValue?.value1} ${entertedValue?.typeDropDown} ` : entertedValue?.compValue === '<' ? ` < ${entertedValue?.value2} ${entertedValue?.typeDropDown} ` : entertedValue?.compValue === 'not null' ? `Any` : `None`
            } else if (entertedValue?.hasOwnProperty('typeDropDown')) {
                return entertedValue?.compValue === 'between' ? `BETWEEN ${entertedValue?.value1} ${entertedValue?.typeDropDown} AND ${entertedValue?.value2} ${entertedValue?.typeDropDown}` : entertedValue?.compValue === '>' ? ` > ${entertedValue?.value1} ${entertedValue?.typeDropDown} ` : entertedValue?.compValue === '<' ? ` < ${entertedValue?.value2} ${entertedValue?.typeDropDown} ` : entertedValue?.compValue === 'not null' ? `Any` : `None`
            } else {
                let tempVal1 = fieldType === 'dateRange' && entertedValue?.value1 ? getFormattedDate(entertedValue?.value1) : entertedValue?.value1
                let tempVal2 = fieldType === 'dateRange' && entertedValue?.value2 ? getFormattedDate(entertedValue?.value2) : entertedValue?.value2
                return entertedValue?.compValue === 'between' ? `BETWEEN ${tempVal1} AND ${tempVal2}` : entertedValue?.compValue === '>' ? ` > ${tempVal1} ` : entertedValue?.compValue === '<' ? ` < ${tempVal2} ` : entertedValue?.compValue === 'not null' ? `Any` : `None`
            }
        }
    }

    checkDuplicate = (fieldType, entertedValue, existingEle) => {
        if (fieldType === 'text' || fieldType === 'Select' || fieldType === 'State' || fieldType === 'date' || fieldType === 'textArea') {
            return !existingEle?.filterFieldValue?.some(ele => ele === entertedValue)
        } else if (fieldType === 'SearchSelect' || fieldType === 'Search') {
            return !existingEle?.filterFieldValue?.some(ele => ele?.value1 === entertedValue?.value1 && ele?.value2 === entertedValue?.value2)
        } else {
            if (entertedValue?.hasOwnProperty('typeDropDown') && entertedValue?.hasOwnProperty('signType')) {
                return !existingEle?.filterFieldValue?.some(ele => ele?.value1 === entertedValue?.value1 && ele?.value2 === entertedValue?.typeDropDown && ele?.typeDropDown === entertedValue?.compValue && ele?.compValue === entertedValue?.value2 && ele?.signType === entertedValue?.signType)
            } else if (entertedValue?.hasOwnProperty('typeDropDown')) {
                return !existingEle?.filterFieldValue?.some(ele => ele?.value1 === entertedValue?.value1 && ele?.value2 === entertedValue?.value2 && ele?.compValue === entertedValue?.compValue && ele?.typeDropDown === entertedValue?.typeDropDown)
            } else {
                return !existingEle?.filterFieldValue?.some(ele => ele?.value1 === entertedValue?.value1 && ele?.value2 === entertedValue?.value2 && ele?.compValue === entertedValue?.compValue)
            }
        }
    }

    handleSelectedFilterValue = (entity, subFilterType, setName, fieldValue, fieldText, fieldType, entertedValue) => {
        let selectedFilters = JSON.parse(JSON.stringify(this.state.selectedFilters))
        let dataBaseMappedField = this.state.dataBaseMapping?.find(item => item?.query_build_entity?.toLowerCase() === entity?.toLowerCase()
            && item?.query_build_group_name?.toLowerCase() === subFilterType?.toLowerCase()
            && item?.query_build_set_name?.toLowerCase() === setName?.toLowerCase()
            && item?.group_field?.toLowerCase() === fieldText?.toLowerCase())
        let apiCompValue = this.generateApiValue(fieldType, entertedValue, fieldText)
        let uiCompValue = this.generateUIValue(fieldType, entertedValue)
        let multipleFields = dataBaseMappedField?.query_build_formula_clause?.split(',')
        let dbQuery = dataBaseMappedField?.query_build_alias_name && dataBaseMappedField?.query_build_field_name ? `${dataBaseMappedField?.query_build_alias_name}.${dataBaseMappedField?.query_build_field_name}` : null
        let tempdbQuery = dataBaseMappedField?.query_build_formula_clause ? entertedValue?.hasOwnProperty('typeDropDown') ? `${multipleFields[0]}` : `${dataBaseMappedField?.query_build_formula_clause}` : null
        let checkExistIdx = selectedFilters?.findIndex(item => item?.entity === entity && item?.setName === setName && item?.subFilterType === subFilterType && item?.fieldValue === fieldValue)
        if (checkExistIdx === -1) {
            let newObj = {
                entity: entity,
                subFilterType: subFilterType,
                setName: setName,
                fieldValue: fieldValue,
                fieldText: fieldText,
                fieldType: fieldType,
                filterFieldValue: [entertedValue],
                uiCompValueArr: [uiCompValue],
                apiCompValueArr: [apiCompValue],
                dbTableName: dataBaseMappedField?.query_build_alias_name,
            }
            newObj.uiShow = `${fieldText} : ${uiCompValue}`
            newObj.backEndShow = dbQuery ? `${dbQuery} ${apiCompValue}` : null
            if (entertedValue?.hasOwnProperty('typeDropDown') && entertedValue?.hasOwnProperty('signType')) {
                newObj.tempbackEndShow = tempdbQuery ? `(${tempdbQuery} ${apiCompValue}) AND (${multipleFields[1]} = '${entertedValue?.typeDropDown}') AND (${multipleFields[2]} = '${entertedValue?.signType}')` : null
            } else if (entertedValue?.hasOwnProperty('typeDropDown')) {
                newObj.tempbackEndShow = tempdbQuery ? `(${tempdbQuery} ${apiCompValue}) AND (${multipleFields[1]} = '${entertedValue?.typeDropDown}')` : null
            } else {
                newObj.tempbackEndShow = tempdbQuery ? `${tempdbQuery} ${apiCompValue}` : null
            }
            selectedFilters.push(newObj)
        } else {
            if (this.checkDuplicate(fieldType, entertedValue, selectedFilters[checkExistIdx])) {
                if (uiCompValue === 'Any' || uiCompValue === 'None' || ((selectedFilters[checkExistIdx]?.uiCompValueArr[0] === 'Any' || selectedFilters[checkExistIdx]?.uiCompValueArr[0] === 'None') && !nullCheckValues?.includes(uiCompValue))) {
                    selectedFilters[checkExistIdx].uiShow = `${fieldText} : ${uiCompValue}`
                    selectedFilters[checkExistIdx].backEndShow = dbQuery ? `${dbQuery} ${apiCompValue}` : null
                    if (entertedValue?.hasOwnProperty('typeDropDown') && entertedValue?.hasOwnProperty('signType')) {
                        selectedFilters[checkExistIdx].tempbackEndShow = tempdbQuery ? `(${tempdbQuery} ${apiCompValue}) AND (${multipleFields[1]} = ${entertedValue?.typeDropDown}) AND (${multipleFields[2]} = '${entertedValue?.signType}')` : null
                    } else if (entertedValue?.hasOwnProperty('typeDropDown')) {
                        selectedFilters[checkExistIdx].tempbackEndShow = tempdbQuery ? `(${tempdbQuery} ${apiCompValue}) AND (${multipleFields[1]} = '${entertedValue?.typeDropDown}')` : null
                    } else {
                        selectedFilters[checkExistIdx].tempbackEndShow = tempdbQuery ? `${tempdbQuery} ${apiCompValue}` : null
                    }
                    selectedFilters[checkExistIdx].filterFieldValue = [entertedValue]
                    selectedFilters[checkExistIdx].uiCompValueArr = [uiCompValue]
                    selectedFilters[checkExistIdx].apiCompValueArr = [apiCompValue]
                } else {
                    selectedFilters[checkExistIdx].uiShow = `${fieldText} : ${uiCompValue} OR ${selectedFilters[checkExistIdx].uiCompValueArr?.map((item, idx) => idx === 0 ? item : ' OR ' + item)}`
                    selectedFilters[checkExistIdx].backEndShow = dbQuery ? `${dbQuery} ${apiCompValue} ${selectedFilters[checkExistIdx].backEndShow ? ' OR ' + selectedFilters[checkExistIdx].backEndShow : ''}` : selectedFilters[checkExistIdx].backEndShow ? selectedFilters[checkExistIdx].backEndShow : null
                    if (entertedValue?.hasOwnProperty('typeDropDown') && entertedValue?.hasOwnProperty('signType')) {
                        selectedFilters[checkExistIdx].tempbackEndShow = tempdbQuery ? `(${tempdbQuery} ${apiCompValue}) AND (${multipleFields[1]} = '${entertedValue?.typeDropDown}') AND (${multipleFields[2]} = '${entertedValue?.signType}') ${selectedFilters[checkExistIdx].tempbackEndShow ? ' OR ' + selectedFilters[checkExistIdx].tempbackEndShow : ''}` : selectedFilters[checkExistIdx].tempbackEndShow ? selectedFilters[checkExistIdx].tempbackEndShow : null
                    } else if (entertedValue?.hasOwnProperty('typeDropDown')) {
                        selectedFilters[checkExistIdx].tempbackEndShow = tempdbQuery ? `(${tempdbQuery} ${apiCompValue}) AND (${multipleFields[1]} = '${entertedValue?.typeDropDown}') ${selectedFilters[checkExistIdx].tempbackEndShow ? ' OR ' + selectedFilters[checkExistIdx].tempbackEndShow : ''}` : selectedFilters[checkExistIdx].tempbackEndShow ? selectedFilters[checkExistIdx].tempbackEndShow : null
                    } else {
                        selectedFilters[checkExistIdx].tempbackEndShow = tempdbQuery ? `${tempdbQuery} ${apiCompValue} ${selectedFilters[checkExistIdx].tempbackEndShow ? ' OR ' + selectedFilters[checkExistIdx].tempbackEndShow : ''}` : selectedFilters[checkExistIdx].tempbackEndShow ? selectedFilters[checkExistIdx].tempbackEndShow : null
                    }
                    selectedFilters[checkExistIdx].filterFieldValue.push(entertedValue)
                    selectedFilters[checkExistIdx].uiCompValueArr.push(uiCompValue)
                    selectedFilters[checkExistIdx].apiCompValueArr.push(apiCompValue)
                }
            }
        }
        this.generateQueryFields(selectedFilters)
        this.setState({ selectedFilters: selectedFilters })
    }

    generateQueryFields = (selectedFilters) => {
        let updateFromQuery = { ...this.state.fromQuery }
        let updateWhereQuery = ''
        let updateTempWhereQuery = ''
        for (let i = 0; i < selectedFilters.length; i++) {
            updateFromQuery[selectedFilters[i].dbTableName] = 1
            updateWhereQuery = selectedFilters[i]?.backEndShow ? updateWhereQuery.length === 0 ? `${updateWhereQuery} (${selectedFilters[i]?.backEndShow})` : `${updateWhereQuery} AND (${selectedFilters[i]?.backEndShow})` : updateWhereQuery
            updateTempWhereQuery = selectedFilters[i]?.tempbackEndShow ? updateTempWhereQuery.length === 0 ? `${updateTempWhereQuery} (${selectedFilters[i]?.tempbackEndShow})` : `${updateTempWhereQuery} AND (${selectedFilters[i]?.tempbackEndShow})` : updateTempWhereQuery
        }
        updateWhereQuery = updateWhereQuery && updateWhereQuery?.length > 0 ? '(' + updateWhereQuery + ')' : null
        updateTempWhereQuery = updateTempWhereQuery && updateTempWhereQuery?.length > 0 ? '(' + updateTempWhereQuery + ')' : null
        this.setState({ fromQuery: updateFromQuery, whereQuery: updateWhereQuery, tempWhereQuery: updateTempWhereQuery }, () => {
            this.getAdvanceSearchData()
        })
    }

    getAdvanceSearchData = () => {
        let load = {
            "payload": 'casterFeatureAdvanceSearch',
            "input": btoa(JSON.stringify({
                "where_query": this.state.whereQuery || '',
                "temp_where_query": this.state.tempWhereQuery || '',
                "from_query": this.state.fromQuery,
                "order_by_clause": this.state.orderByClause || '',
                "page_no": this.state.page + 1,
                "page_count": this.state.rowsPerPage,
                "person_type": this.state.personType?.toUpperCase() || ''
            })),
            "dataType": this.state.entity?.toUpperCase(),
            "encryptiontype": "1",
        }
        this.setState({ isLoadingSets: true })
        CasterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if (response?.statusCode == 200) {
                        let tempResponse = JSON.parse(response?.body)?.data;
                        let finalList = JSON.parse(response?.body)?.data;
                        if (tempResponse.length > 0) {
                            for (let i = 0; i < tempResponse.length; i++) {
                                finalList[i].project_notes = ReactHtmlParser(tempResponse[i]?.project_notes);
                            }
                        }
                        this.setState({ list: finalList, count: JSON.parse(response?.body)?.count, isLoadingSets: false })
                    }
                }
            })

    }

    getAdvanceSearchReport = (payloadName, flagName) => {
        let load = {
            "payload": payloadName,
            "searchJson": btoa(JSON.stringify({
                "where_query": this.state.whereQuery || '',
                "temp_where_query": this.state.tempWhereQuery || '',
                "from_query": this.state.fromQuery,
                "page_no": this.state.page + 1,
                "page_count": this.state.rowsPerPage,
                "order_by_clause": this.state.orderByClause || '',
                "person_type": this.state.personType?.toUpperCase() || '',
                "columns": this.state.availableColumns.filter(item => item?.isChecked === 1)?.map(ele => ele?.value === 'union' ? "`union`" : ele?.value)?.join()
            })),
            "reportName": "advanceSearchPdf",
            "dataType": this.state.entity?.toUpperCase(),
            "encryptionType": 1,
            "riderFlag": 0,
        }
        this.setState({ [flagName]: true })
        CasterService.getDataWS(load)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if (response?.statusCode == 200) {
                        let url = response?.body
                        if (url) {
                            const link = document.createElement('a');
                            link.href = url;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                        this.setState({ [flagName]: false })
                    }
                }
            })

    }

    handleSortColumn = (colName) => {
        let colObj = this.state.availableColumns?.find(item => item?.text === colName)
        let orderByStr = this.state.orderByClause?.split(' ')
        let config = { ...this.state.config }
        let headingidx = config.headings?.findIndex(item => item?.headingLabel === colName)
        let updateOrderByStr = ''
        if (orderByStr[2] === colObj?.value) {
            updateOrderByStr = `ORDER BY ${orderByStr[2]} ${orderByStr[3] === 'ASC' ? ' DESC' : ' ASC'}`
            config.headings[headingidx].icon = orderByStr[3] === 'ASC' ? "sort-alpha-down" : "sort-alpha-up"
        } else {
            updateOrderByStr = `ORDER BY ${colObj?.value} ASC`
            for (let i = 0; i < config?.headings?.length; i++) {
                config.headings[i].icon = ''
            }
            config.headings[headingidx].icon = "sort-alpha-up"
        }
        let fromQuery = { ...this.state.fromQuery }
        fromQuery[colObj.tableName] = 1
        this.setState({ orderByClause: updateOrderByStr, fromQuery: fromQuery, config: config }, () => {
            if (this.state.count > 0) {
                this.getAdvanceSearchData()
            }
        })
    }


    getSearchOptions = (objectType, searchString, stateName, valueKey, textKey, distinct = false) => {
        let url = searchString ? `objectType=${objectType}&searchString=${searchString}` : `objectType=${objectType}`
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterFeatureSearch?${url}`
        ).then((response) => {
            let list = []
            list.push({ value: 'null', text: 'None' })
            list.push({ value: 'not null', text: 'Any' })
            for (let i = 0; i < response.data.length; i++) {
                list.push({ value: stateName === 'activityTypeDetails' ? "'" + response.data[i][valueKey] + "'" : response.data[i][valueKey], text: response.data[i][textKey] })
            }
            if (distinct) {
                let uniqueList = list.filter((obj, index) => {
                    return index === list.findIndex(o => obj.text === o.text);
                });
                this.setState({ [stateName]: uniqueList?.map(item => ({ value: item?.value, label: item?.text })) });
            } else {
                this.setState({ [stateName]: list });
            }
        });
    };

    getLookUpValue = (tableName, stateName) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${tableName}`)
            .then(response => {
                let list = []
                list.push({ value: 'null', label: 'None' })
                list.push({ value: 'not null', label: 'Any' })
                for (let i = 0; i < response.data.length; i++) {
                    list.push({ value: response.data[i]?.lookup_data_id, label: response.data[i]?.lookup_value })
                }
                this.setState({ [stateName]: list });
            },
                (err) => {
                    console.log("Error in fetching Text:", err)
                })
    }

    handleClose = () => {
        this.setState({ openTalentPerformerModal: false })
    }

    functions = {
        handleShowTalentPopup: (id = null, talent_name) => {
            let talent = {
                value: id,
                text: talent_name,
                headerName: talent_name,
                aka: talent_name.includes('(') && talent_name.includes(')') ? talent_name?.match(/\(([^)]+)\)/)[1] : " "
            }
            this.setState({ showTalentPopup: true, talentId: id, talentSelected: talent });
        },
        handleHideTalentPopup: () => {
            this.setState({ showTalentPopup: false, talentId: null });
        },
        handleShowAgencyPopup: (id = null, agency_name, alias) => {
            let agency = {
                agency_id: id,
                agency_name: agency_name,
                aka: alias
            }
            this.setState({ showAgencyPopup: agency, agencyId: id, agencySelected: agency })
        },
        handleHideAgencyPopup: () => {
            this.setState({ showAgencyPopup: false });
        },
        handleShowRepresentativePopup: (id = null, representative_name) => {
            let rep = {
                value: id,
                text: representative_name
            }
            this.setState({ showRepresentativePopup: true, representativeId: id, representativeSelected: rep })
        },
        handleHideRepresentativePopup: () => {
            this.setState({ showRepresentativePopup: false, representativeId: null })
        },
        handleHideCrewPopup: () => {
            this.setState({ showCrewPopup: false });
        },
        handleOpenPerformerRecord: (project_id, id) => {
            this.setState({ talent_project_id: project_id }, () => {
                this.getProjectDetails(project_id)
                this.getTalentPerformerDetails(id, true, project_id)
            })

        }
    }

    getAge = (birthDate) => {
        if (birthDate) {
            var today = new Date();
            let birthDateFormatted = new Date(birthDate)
            var age = today?.getFullYear() - birthDateFormatted?.getFullYear();
            var m = today?.getMonth() - birthDateFormatted?.getMonth();
            if (m < 0 || (m === 0 && today?.getDate() < birthDateFormatted?.getDate())) {
                age--;
            }
            age = (age && age > 0) ? age : (age === 0 && age > 0) ? age : 0;
            this.setState({ age: age })
        } else {
            this.setState({ age: 0 })
        }
    }
    getTalentPerformerDetails = (performer_deal_id, openModal = false, project_id = this.props?.project_id) => {
        // this.getPeformerDetailsFlags(performer_deal_id)
        this.setState({ performerDetailsLoading: true })
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/casterFeaturePerformerDeal?project_id=${project_id}&performer_deal_id=${performer_deal_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("response", response)
                if (response?.data?.error || !response?.data) {
                    this.setState({ performerDetailsLoading: false })
                    console.log("Error fetching talent Setails");
                }
                else {
                    console.log("Response", response.data[0]);
                    let formattedList = response.data[0]
                    let loanout_present = formattedList.loanout_company ? true : false
                    formattedList.deal_date = formattedList.deal_date != "00/00/0000" ? getFormattedDate(formattedList.deal_date, true) : null
                    formattedList.ssn_type = formattedList?.ssn ? 'ssn' : 'fid'
                    formattedList.production_company = { value: formattedList.production_company, text: formattedList.production_company }
                    // formattedList.production_company = null
                    formattedList.loanout_company_state = { value: formattedList.loanout_company, text: formattedList.loanout_company }
                    formattedList.immigration_status = { id: formattedList.immigration_id, value: formattedList.immigration_status, text: formattedList.immigration_status }
                    formattedList.country_inc = { id: formattedList.country_inc_id, value: formattedList.country_inc, text: formattedList.country_inc }
                    formattedList.state_inc = (formattedList.country_inc?.value === 'United States' || formattedList.country_inc?.value === 'USA' || formattedList.country_inc?.value === 'US') ? { id: formattedList.state_inc_id, value: formattedList?.state_inc?.includes("-") ? formattedList?.state_inc?.split("-")?.[1] : formattedList?.state_inc, text: formattedList?.state_inc?.includes("-") ? formattedList?.state_inc?.split("-")?.[1] : formattedList?.state_inc } : formattedList.state_inc
                    CasterService.getData(Constants.CasterServiceBaseUrl +
                        `/casterFeatureTalentDetails?id=${formattedList.talent_id}`, this.props.userContext?.active_tenant?.tenant_id)
                        .then(response => {
                            console.log("response", response)
                            if (response?.data?.error || !response?.data) {
                                console.log("Error fetching talent Setails");
                            }
                            else {
                                console.log("Response", response);
                                let loanoutOptions = response.data[0].legal_list?.map(i => ({ text: i?.loanout_company, value: i?.loanout_company }))
                                let legalList = response.data[0].legal_list
                                let alias_options = [{ value: formattedList.talent_name, label: formattedList.talent_name }]
                                if (response.data[0]?.akas.length > 0) {
                                    for (let x in response.data[0]?.akas) {
                                        alias_options.push({ value: `${formattedList.talent_name} PKA ${response.data[0]?.akas[x].aka}`, label: `${formattedList.talent_name} PKA ${response.data[0]?.akas[x].aka}` })
                                    }
                                    for (let x in response.data[0]?.akas) {
                                        alias_options.push({ value: `${response.data[0]?.akas[x].aka}`, label: `${response.data[0]?.akas[x].aka}` })
                                    }
                                }
                                let aliases = response.data[0].akas?.length > 0 ? response.data[0].akas?.map(aka => aka.aka) : []
                                formattedList.aliases = aliases.join()
                                formattedList.primary_name = response.data[0]?.primary_name === null ? formattedList?.talent_name : response.data[0]?.primary_name
                                // formattedList.selected_alias_name === null ? formattedList.selected_alias_name = "" : formattedList.selected_alias_name = formattedList.selected_alias_name
                                this.setState({ alias_options: alias_options, loanoutOptions: loanoutOptions, default_currency: formattedList?.default_currency, legalList: legalList }, () => {
                                    this.setState({ performerDetailsLoading: false, performerDetails: formattedList, loanout_present: loanout_present }, () => {
                                        this.setAkaValues(this.state.alias_options);
                                        this.getAge(this.state.performerDetails?.birth_date);
                                        if (openModal) {
                                            this.setState({ openTalentPerformerModal: true }, () => {
                                                console.log('projectProdCompanies2', this.state.projectProdCompanies)
                                            })
                                        }
                                    })
                                })
                            }
                        },
                            (err) => {
                                console.log("Error in fetching Talent Details:", err)
                            })
                    // this.setState({ performerDetailsLoading: false, performerDetails: formattedList, loanout_present: loanout_present }, () => {
                    //     this.getAge(this.state.performerDetails?.birth_date);
                    //     if (openModal) {
                    //         this.setState({ openTalentPerformerModal: true })
                    //     }
                    // })
                }
            },
                (err) => {
                    this.setState({ performerDetailsLoading: false })
                    console.log("Error in fetching Talent Details:", err)
                })
    }

    setAkaValues = (newakas) => {
        console.log('updated akas', newakas)
        this.setState(prevState => ({
            showTalentPopup: {
                ...prevState.showTalentPopup,
                ['akas']: newakas
            }
        }))
    }

    render() {
        return (
            <MDBContainer disabled={this.state.isLoadingSets} fluid className="AdvancedSearchContainer p-0">
                {this.state?.openRepresentativePopOver && <NewRepresentative
                    id={"NewRepPerformer"}
                    open={this.state.openRepresentativePopOver || false}
                    setPopOver={this.state?.openRepresentativePopOver}
                    handleClose={() => this.setState({ openRepresentativePopOver: null })}
                    representativeData={{ optionType: "new", text: null, value: null }}
                    updateRepId={(id) => {
                        let repSelected = this.state.repSelected ? { ...this.state.repSelected } : {}
                        repSelected.id = id
                        this.setState({ repSelected: repSelected })
                    }}
                    setObjectSelected={(type, value) => { this.setState({ showRepresentativeModal: true, repSelected: value }) }}
                    setNewObjectSelected={(type, value) => this.setState({ openRepresentativePopOver: null, repSelected: null })}
                />}
                {this.state.newTalentPopOver &&
                    <NewTalent
                        id={'newtalenpopoverfromadvancesearch'}
                        module={"Performer"}
                        open={this.state.newTalentPopOver || false}
                        setPopOver={this.state?.newTalentPopOver}
                        handleClose={() => { this.setState({ newTalentPopOver: null }) }}
                        talentData={null}
                        newTalentPerformer={(value) => {
                            this.setState({
                                showTalentPopup: {
                                    talent_id: value?.value || null,
                                    talent_name: value?.text || null,
                                    // akas: value?.headerName || null,
                                }
                            })
                        }}
                    />
                }
                {this.state.openCrewPopOver && <NewCrewModal
                    isDashboard={true}
                    id={'newCrewFromadvancesea'}
                    open={this.state.openCrewPopOver}
                    setPopOver={this.state.openCrewPopOver}
                    handleClose={() => this.setState({ openCrewPopOver: null })}
                    crewData={''}
                    navList={crewNavList}
                    setObjectSelected={(type, value) => { this.setState({ showCrewPopup: { crew_id: value?.value, crew_name: value?.text } }) }}
                    setNewObjectSelected={(type, value) => { this.setState({ showCrewPopup: null, openCrewPopOver: null }) }}
                />}
                {this.state.openAgencyPopOver && <NewAgency
                    id={'newAgencyFromadvancesea'}
                    open={this.state.openAgencyPopOver}
                    setPopOver={this.state.openAgencyPopOver}
                    handleClose={() => this.setState({ openAgencyPopOver: null })}
                    handleCloseAgencyPopOver={() => this.setState({ openAgencyPopOver: null })}
                    agencySelected={null}
                    setObjectSelected={(type, value) => { this.setState({ showAgencyPopup: { agency_id: value?.value, agency_name: value?.text } }) }}
                    setNewObjectSelected={(type, value) => { this.setState({ showAgencyPopup: null, openAgencyPopOver: null }) }}
                />}
                {
                    this.state.showTalentPopup &&
                    <ModalComponent
                        id={this.state.showTalentPopup?.talent_id}
                        open={this.state.showTalentPopup || false}
                        handleClose={() => { this.getAdvanceSearchData(),
                            this.setState({ showTalentPopup: false })}}
                        tabList={talentFCNavList}
                        headerText={"Talent"}
                        mode={'edit'}
                        isProfileDiv={true}
                        addButtonText={"New Talent"}
                        objectType={'TALENT'}
                        objectId={this.state.showTalentPopup?.talent_id}
                        headerName={this.state.showTalentPopup?.talent_name}
                        akas={this.state.showTalentPopup?.selected_alias_name || null}
                        dataUrl='talentDetails'
                        navFuns={this.functions}
                        setAkaValues={this.setAkaValues}
                        isOpeningFromAdvSearch={true} />
                }
                {
                    this.state.showRepresentativeModal &&
                    <ModalComponent
                        id={this.state.repSelected?.value?this.state.repSelected?.value:this.state.repSelected?.representative_id}
                        open={this.state.showRepresentativeModal || false}
                        handleClose={(is_edit) => this.setState({ showRepresentativeModal: false, repSelected: null },is_edit ? ()=>this.getAdvanceSearchData():null)}
                        tabList={representativeFCNavList}
                        headerText={"Representative"}
                        mode="edit"
                        isProfileDiv={true}
                        addButtonText={"New Representative"}
                        objectType={'REPRESENTATIVE'}
                        objectId={this.state.repSelected?.value?this.state.repSelected?.value:this.state.repSelected?.representative_id}
                        akas={this.state.repSelected?.aka ? this.state.repSelected?.aka : ""}
                        headerName={this.state.repSelected?.headerName}
                        navFuns={this.functions} />
                }
                {
                    this.state.showAgencyPopup &&
                    <ModalComponent
                        id={this.state.showAgencyPopup?.agency_id}
                        agencyId={{ value: this.state.showAgencyPopup?.agency_id }}
                        open={this.state.showAgencyPopup || false}
                        handleClose={() => {this.getAdvanceSearchData(),
                            this.setState({ showAgencyPopup: null })}}
                        tabList={agencyNavList}
                        headerName={this.state?.showAgencyPopup?.agency_name}
                        headerText={"Agency"}
                        mode="edit"
                        isProfileDiv={true}
                        addButtonText={"New Agency"}
                        objectType={"AGENCY"}
                        akas={this.state.showAgencyPopup?.agency_aliases || null}
                        navFuns={this.functions}
                        dataUrl='casterFeatureAgencyDetails' />
                }
                {this.state.showCrewPopup &&
                    <ModalComponent
                        id={this.state.showCrewPopup?.crew_id}
                        akas={this.state?.showCrewPopup?.aka || null}
                        open={this.state.showCrewPopup || false}
                        handleClose={() => this.setState({ showCrewPopup: null })}
                        tabList={crewNavList}
                        headerText={"Crew"}
                        mode="edit"
                        isProfileDiv={true}
                        addButtonText={"New Crew"}
                        objectType={'CREW'}
                        objectId={this.state?.showCrewPopup?.crew_id || null}
                        dataUrl='casterFeatureCrewDetails'
                        headerName={this.state.showCrewPopup?.crew_name}
                        navFuns={this.functions}
                    />
                }
                {this.state.showNewProduction &&
                    <Modal
                        id={this.state.showNewProduction === 'New' ? null : this.state.showNewProduction}
                        open={this.state.showNewProduction || false}
                        handleClose={() => { this.setState({ showNewProduction: null }) }}
                        modalName={"Project"}
                        headerText={"Project"}
                        fromAdvancesearch={true}
                        mode={this.state.showNewProduction === 'New' ? 'New' : 'Edit'}
                        toggleRefreshPage={() => { console.log('Modal close') }}
                    />
                }
                {this.state.openTalentPerformerModal ?
                    <Modal
                        open={this.state.openTalentPerformerModal}
                        age={this.state.age}
                        modalName={"Talent Performer"}
                        selectedTalent={this.state.selectedTalent}
                        project_id={this.state?.talent_project_id}
                        handleClose={() => this.handleClose()}
                        primaryButtonText={"Create"}
                        isNew={this.state.isNew}
                        headerText={(this.state?.isNew || this.state?.existingTalent) ? `New Performer Record | ${this.state?.performerHeader}` : "Edit Names/Project Details"}
                        mode={"Both"}
                        headerName={this.state.performerHeader}
                        getTalentPerformerDetails={this.getTalentPerformerDetails.bind(this)}
                        // getPerformerTableDetails={() => this.getPerformerTableDetails()}
                        // viewPerformerData={() => this.viewPerformerData()}
                        performerDetails={this.state.performerDetails}
                        existingTalent={this.state.existingTalent}
                        performer_deal_id={this.state?.performerDetails?.performer_deal_id}
                        loanoutOptions={this.state.loanoutOptions}
                        legalList={this.state.legalList}
                        getAge={() => this.getAge()}
                        alias_options={this.state.alias_options}
                        navFuns={this.functions}
                        projectProdCompanies={this.state?.projectProdCompanies}
                        advSearch={true}
                    /> : null
                }
                <MDBRow>
                    <MDBCol md={10} className='header p-0'>
                        <BasicLabel type="overAllHeader" text={"Advanced Search"} />
                    </MDBCol>
                    <MDBCol md={2} id={'advanceSearchCreateNewBtnforDataMaintenance'} className='pointer mt-2'>
                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" && 
                        <BasicButton
                            type="inline"
                            className="FR MR1"
                            variant="contained"
                            text={"Create New"}
                            onClick={(e) => this.setState({ openDataMaintenance: e.currentTarget })}
                        />}
                        <Popover
                            id={this.state.openDataMaintenance ? 'simple-popover' : null}
                            open={Boolean(this.state.openDataMaintenance)}
                            anchorEl={this.state.openDataMaintenance}
                            onClose={() => this.setState({ openDataMaintenance: null })}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            PaperProps={{ style: { width: '200px' } }}
                        >
                            <MDBContainer className='newEntiyPopOver'>
                                <div id={'advanceSearchNewTalent'} onClick={(e) => this.setState({ newTalentPopOver: document.getElementById('advanceSearchCreateNewBtnforDataMaintenance'), openDataMaintenance: null })}>Talent</div>
                                <div id={'advanceSearchNewRep'} onClick={(e) => { this.setState({ openRepresentativePopOver: document.getElementById('advanceSearchCreateNewBtnforDataMaintenance'), openDataMaintenance: null }) }}>Representative</div>
                                <div id={'advanceSearchNewCrew'} onClick={(e) => { this.setState({ openCrewPopOver: document.getElementById('advanceSearchCreateNewBtnforDataMaintenance'), openDataMaintenance: null }) }}>Crew</div>
                                <div id={'advanceSearchNewagency'} onClick={(e) => { this.setState({ openDataMaintenance: null, openAgencyPopOver: document.getElementById('advanceSearchCreateNewBtnforDataMaintenance') }) }}>Company</div>
                                <div id={'advanceSearchNewProject'} onClick={() => { this.setState({ showNewProduction: 'New' }) }}>Project</div>
                            </MDBContainer>
                        </Popover>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={3} className="entity-selection p-0">
                        <SelectField
                            id={"entity"}
                            label={"Select an object to search"}
                            defaultMenuText={"-Select-"}
                            options={this.state.entityList || []}
                            value={this.state.entity || null}
                            onChange={(e, value) => this.onChangeHeaderOptionsCallBack('entity', e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol md={5}>{this.state.count !== 0 && <span className="FR">PDF can have max 2k records</span>}</MDBCol>
                    <MDBCol md={2} className='purpleColorText'>
                        <BasicButton
                            type="inline"
                            className="FR MR1"
                            variant="outlined"
                            icon="file-pdf"
                            text={this.state.isPDFDownload ? <CircularProgress color="inherit" size={20} /> : "Export to PDF"}
                            disabled={this.state.count === 0 || false}
                            onClick={() => this.getAdvanceSearchReport('casterFeatureGenerateReport', 'isPDFDownload')}
                        />
                    </MDBCol>
                    <MDBCol md={2} className='greenColorText'>
                        <BasicButton
                            type="inline"
                            className="FR MR1"
                            variant="outlined"
                            icon="file-excel"
                            text={this.state.isEXCELDownload ? <CircularProgress color="inherit" size={20} /> : "Export as Excel"}
                            disabled={this.state.count === 0 || false}
                            onClick={() => this.getAdvanceSearchReport('casterFeatureAdvanceSearchExcel', 'isEXCELDownload')}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={3} className="p-0 maxHeightClass">
                        <MDBCard className="filter-card">
                            <MDBCardBody>
                                <MDBCardText>
                                    <MDBRow className="filter-section">
                                        <MDBCol md={9} className="criteria-heading p-0">
                                            <BasicLabel type="headerText" text={"Criteria"} />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        {this.state.selectedGroups?.map((item, filterIndex) => (
                                            <Accordion
                                                key={'filter' + '-' + filterIndex}
                                                expanded={item?.isExpand || false}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id={"panel1a-header" + filterIndex}
                                                    onClick={() => {
                                                        let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                        selectedFilterGroup[filterIndex].isExpand = !selectedFilterGroup[filterIndex].isExpand
                                                        this.setState({ selectedGroups: selectedFilterGroup })
                                                    }}
                                                >
                                                    <Typography>{item?.text}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {item?.filterCategoryTypes?.map((subFilterItem, subFilterIndex) => (
                                                        <div key={subFilterIndex + filterIndex + subFilterItem?.type}>
                                                            <div
                                                                className="d-flex core-field"
                                                            // onClick={() => {
                                                            //     let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                            //     selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].isExpand = !selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].isExpand
                                                            //     this.setState({ selectedGroups: selectedFilterGroup })
                                                            // }}
                                                            >
                                                                <div>
                                                                    <MDBIcon icon="folder" className='pr-2' />
                                                                    <span>{subFilterItem?.type}</span>
                                                                </div>
                                                                <span onClick={() => {
                                                                    let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].isExpand = !selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].isExpand
                                                                    this.setState({ selectedGroups: selectedFilterGroup })
                                                                }}>{subFilterItem?.isExpand ? <MDBIcon className='pr-2 pointer' icon="minus" /> : <MDBIcon icon="plus" className='pr-2 pointer' />}</span>
                                                            </div>
                                                            <div>
                                                                {subFilterItem?.isExpand && subFilterItem.filterCategories?.map((field, fieldIndex) => {
                                                                    return <MDBRow key={fieldIndex + subFilterIndex + filterIndex + 'fieldFilter' + field?.value}>
                                                                        <MDBCol md={11}>
                                                                            <span className='pl-2 innerFieldlabel'>{field?.text}</span>
                                                                            {field?.isExpand && <div className='pl-4'>
                                                                                {field?.type === 'SearchSelect' && <SearchSelectField
                                                                                    id={'adcSearch' + fieldIndex + subFilterIndex + filterIndex + field?.text}
                                                                                    label={''}
                                                                                    placeholder={'- Search -'}
                                                                                    width={'100%'}
                                                                                    loading={field.isLoading || false}
                                                                                    detail_selected={null}
                                                                                    options={this.state[field?.optionName] || [{ value: 'not null', text: 'Any' }, { value: 'null', text: 'None' }]}
                                                                                    searchSelect={true}
                                                                                    valueSelected={(e, newValue) => this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, { value1: newValue?.text || null, value2: newValue?.value || null })}
                                                                                    searchText={ev => {
                                                                                        if (ev.target.value !== "" && ev.target.value !== null) {
                                                                                            this.getSearchOptions(field.tableName, ev.target.value, field.optionName, field?.valueKey, field?.textKey)
                                                                                        }
                                                                                    }}
                                                                                />}
                                                                                {field?.type === 'Select' && <SelectField
                                                                                    id={'adcSearch' + fieldIndex + subFilterIndex + filterIndex + field?.text}
                                                                                    placeHolderText="-Select-"
                                                                                    options={this.state[field?.optionName] || []}
                                                                                    value={null}
                                                                                    onChange={(e) => {
                                                                                        if (field?.text === 'Person Type') {
                                                                                            this.setState({ personType: e.currentTarget.outerText }, () => {
                                                                                                if (this.state.selectedFilters?.length > 0) {
                                                                                                    this.getAdvanceSearchData()
                                                                                                }
                                                                                            })
                                                                                        } else {
                                                                                            this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, e.currentTarget.outerText)
                                                                                        }
                                                                                    }}
                                                                                />}
                                                                                {field?.type === 'text' && <MDBRow>
                                                                                    <MDBCol md={11}>
                                                                                        <BasicTextField
                                                                                            width={'100%'}
                                                                                            id={'adcSearch' + fieldIndex + subFilterIndex + filterIndex + field?.text}
                                                                                            value={field?.value1 || null}
                                                                                            onChange={(e) => {
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = e.target?.value?.length > 0 ? e.target.value : null
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }} />
                                                                                    </MDBCol>
                                                                                    <MDBCol className="pointer" md={1} disabled={!field?.value1}>
                                                                                        <MDBIcon icon="plus" onClick={() => {
                                                                                            let tempVal = field?.text === 'SSN' && field?.value1?.length === 9 ? field?.value1.substring(0, 3) + "-" + field?.value1.substring(3, 5) + "-" + field?.value1.substring(5, field?.value1?.length) : field?.value1
                                                                                            this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, tempVal)
                                                                                            let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                            selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                            this.setState({ selectedGroups: selectedFilterGroup })
                                                                                        }} />
                                                                                    </MDBCol>
                                                                                </MDBRow>}
                                                                                {field?.type === 'textArea' && <MDBRow>
                                                                                    <MDBCol md={11}>
                                                                                        <BasicTextArea
                                                                                            width={'100%'}
                                                                                            id={'adcSearch' + fieldIndex + subFilterIndex + filterIndex + field?.text}
                                                                                            value={field?.value1 || null}
                                                                                            onChange={(e) => {
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = e.target?.value?.length > 0 ? e.target.value : null
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }} />
                                                                                    </MDBCol>
                                                                                    <MDBCol className="pointer" md={1} disabled={!field?.value1} >
                                                                                        <MDBIcon icon="plus" onClick={() => {
                                                                                            this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, field?.value1)
                                                                                            let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                            selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                            this.setState({ selectedGroups: selectedFilterGroup })
                                                                                        }} />
                                                                                    </MDBCol>
                                                                                </MDBRow>}
                                                                                {field?.type === 'State' && <MDBRow>
                                                                                    <MDBCol md={11} className='radioGroupForFilters'>
                                                                                        <RadioGroup
                                                                                            value={field?.compValue}
                                                                                            onChange={(e) => {
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].compValue = e.target.value
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }}
                                                                                        >   <MDBRow>
                                                                                                <MDBCol md={6}><FormControlLabel value={'select'} control={<Radio size="small" />} label={'State'} /></MDBCol>
                                                                                                <MDBCol md={6}><FormControlLabel value={'text'} control={<Radio size="small" />} label={"Province"} /></MDBCol>
                                                                                            </MDBRow>
                                                                                        </RadioGroup>
                                                                                        {field?.compValue === 'select' && <SelectField
                                                                                            id={'adcSearch' + fieldIndex + subFilterIndex + filterIndex + field?.text}
                                                                                            placeHolderText="-Select-"
                                                                                            options={this.state[field?.optionName] || []}
                                                                                            value={null}
                                                                                            onChange={(e) => this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, e.currentTarget.outerText)}
                                                                                        />}
                                                                                        {field?.compValue === 'text' && <BasicTextField
                                                                                            width={'100%'}
                                                                                            id={'adcSearch' + fieldIndex + subFilterIndex + filterIndex + field?.text}
                                                                                            value={field?.value1 || null}
                                                                                            onChange={(e) => {
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = e.target?.value?.length > 0 ? e.target.value : null
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }} />}
                                                                                    </MDBCol>
                                                                                    {field?.compValue === 'text' && <MDBCol disabled={!field?.value1} md={1} className="pointer">
                                                                                        <MDBIcon icon="plus"
                                                                                            onClick={() => {
                                                                                                this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, field?.value1)
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].compValue = 'text'
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }}
                                                                                        />
                                                                                    </MDBCol>}
                                                                                </MDBRow>}
                                                                                {field?.type === 'Search' && <SearchSelectField
                                                                                    id={'adcSearch' + fieldIndex + subFilterIndex + filterIndex + field?.text}
                                                                                    width={"100%"}
                                                                                    value={null}
                                                                                    options={this.state[field?.optionName]}
                                                                                    onChange={(e, newValue) => this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, { value1: newValue?.text || null, value2: newValue?.value || null })}
                                                                                />}
                                                                                {field?.type === 'date' && <MDBRow>
                                                                                    <MDBCol md={11}>
                                                                                        <DateField
                                                                                            value={field?.value1}
                                                                                            onChange={(e) => {
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = e.target.value?.length > 0 ? e.target.value : null
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }} />
                                                                                    </MDBCol>
                                                                                    <MDBCol md={1} disabled={!field?.value1} className="pointer">
                                                                                        <MDBIcon icon="plus" onClick={() => {
                                                                                            this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, field?.value1)
                                                                                            let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                            selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                            this.setState({ selectedGroups: selectedFilterGroup })
                                                                                        }} />
                                                                                    </MDBCol>
                                                                                </MDBRow>}
                                                                                {field?.type === 'dateRange' && <MDBRow>
                                                                                    <MDBCol md={11} className='radioGroupForFilters'>
                                                                                        <RadioGroup
                                                                                            value={field?.compValue}
                                                                                            onChange={(e) => {
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].compValue = e.target.value
                                                                                                if (e.target.value === 'none' || e.target.value === 'any') {
                                                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value2 = null
                                                                                                }
                                                                                                if (e.target.value === '>') {
                                                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value2 = null
                                                                                                }
                                                                                                if (e.target.value === '<') {
                                                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                                }
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }}
                                                                                        >   <MDBRow>
                                                                                                {filterTypeDateOptions?.map(option =>
                                                                                                    <MDBCol md={6} key={option?.value + option?.text + 'adcSearch' + fieldIndex + subFilterIndex + filterIndex}><FormControlLabel value={option?.value} control={<Radio size="small" />} label={option?.text} /></MDBCol>
                                                                                                )}
                                                                                            </MDBRow>
                                                                                        </RadioGroup>
                                                                                        <MDBRow>
                                                                                            <MDBCol md={6}>
                                                                                                <DateField
                                                                                                    value={field?.value1}
                                                                                                    disabled={(field?.compValue === 'null' || field?.compValue === 'not null' || field?.compValue === '<') || false}
                                                                                                    onChange={(e) => {
                                                                                                        let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                        selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = e.target.value?.length > 0 ? e.target.value : null
                                                                                                        this.setState({ selectedGroups: selectedFilterGroup })
                                                                                                    }} />
                                                                                            </MDBCol>
                                                                                            <MDBCol md={6}>
                                                                                                <DateField
                                                                                                    value={field?.value2}
                                                                                                    disabled={(field?.compValue === 'null' || field?.compValue === 'not null' || field?.compValue === '>') || false}
                                                                                                    onChange={(e) => {
                                                                                                        let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                        selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value2 = e.target.value?.length > 0 ? e.target.value : null
                                                                                                        this.setState({ selectedGroups: selectedFilterGroup })
                                                                                                    }} />
                                                                                            </MDBCol>
                                                                                        </MDBRow>
                                                                                    </MDBCol>
                                                                                    <MDBCol className="pointer" md={1} disabled={(field?.compValue === 'null' || field?.compValue === 'not null') ? false : field?.compValue === '>' ? !field?.value1 : field?.compValue === '<' ? !field?.value2 : !(field?.value1 && field?.value2)}>
                                                                                        <MDBIcon icon="plus"
                                                                                            onClick={() => {
                                                                                                this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, { 'compValue': field?.compValue || null, 'value1': field?.value1 || null, 'value2': field?.value2 || null })
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value2 = null
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].compValue = 'between'
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }}
                                                                                        />
                                                                                    </MDBCol>
                                                                                </MDBRow>}
                                                                                {field?.type === 'numRange' && <MDBRow>
                                                                                    <MDBCol md={11} className='radioGroupForFilters'>
                                                                                        <RadioGroup
                                                                                            value={field?.compValue}
                                                                                            onChange={(e) => {
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].compValue = e.target.value
                                                                                                if (e.target.value === 'null' || e.target.value === 'not null') {
                                                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value2 = null
                                                                                                }
                                                                                                if (e.target.value === '>') {
                                                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value2 = null
                                                                                                }
                                                                                                if (e.target.value === '<') {
                                                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                                }
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }}
                                                                                        >   <MDBRow>
                                                                                                {filterTypeDateOptions?.map(option =>
                                                                                                    <MDBCol md={6} key={option?.value + option?.text + 'adcSearch' + fieldIndex + subFilterIndex + filterIndex}><FormControlLabel value={option?.value} control={<Radio size="small" />} label={option?.text} /></MDBCol>
                                                                                                )}
                                                                                            </MDBRow>
                                                                                        </RadioGroup>
                                                                                        {(field?.typeDropDown || field?.signType) && <MDBRow className='mb-2'>
                                                                                            {field?.typeDropDown && <MDBCol md={6}>
                                                                                                <SelectField
                                                                                                    label={"Days/Weeks"}
                                                                                                    options={this.state.daysWeeks || []}
                                                                                                    value={field?.typeDropDown || null}
                                                                                                    onChange={(e) => {
                                                                                                        let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                        selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].typeDropDown = e.target.value
                                                                                                        this.setState({ selectedGroups: selectedFilterGroup })
                                                                                                    }}
                                                                                                />
                                                                                            </MDBCol>}
                                                                                            {field?.signType && <MDBCol md={6}>
                                                                                                <SelectField
                                                                                                    label={"+/-"}
                                                                                                    options={this.state.signTypes || []}
                                                                                                    value={field?.signType || null}
                                                                                                    onChange={(e) => {
                                                                                                        let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                        selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].signType = e.target.value
                                                                                                        this.setState({ selectedGroups: selectedFilterGroup })
                                                                                                    }}
                                                                                                />
                                                                                            </MDBCol>}
                                                                                        </MDBRow>}
                                                                                        <MDBRow>
                                                                                            <MDBCol md={6}>
                                                                                                <BasicTextField
                                                                                                    width={'100%'}
                                                                                                    id={'adcSearch' + fieldIndex + subFilterIndex + filterIndex + field?.text}
                                                                                                    disabled={(field?.compValue === 'null' || field?.compValue === 'not null' || field?.compValue === '<') || false}
                                                                                                    value={field?.value1 || null}
                                                                                                    onChange={(e) => {
                                                                                                        let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                        selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = e.target?.value?.length > 0 ? e.target.value : null
                                                                                                        this.setState({ selectedGroups: selectedFilterGroup })
                                                                                                    }} />
                                                                                            </MDBCol>
                                                                                            <MDBCol md={6}>
                                                                                                <BasicTextField
                                                                                                    width={'100%'}
                                                                                                    id={'adcSearch' + fieldIndex + subFilterIndex + filterIndex + field?.text}
                                                                                                    disabled={(field?.compValue === 'null' || field?.compValue === 'not null' || field?.compValue === '>') || false}
                                                                                                    value={field?.value2 || null}
                                                                                                    onChange={(e) => {
                                                                                                        let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                        selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value2 = e.target?.value?.length > 0 ? e.target.value : null
                                                                                                        this.setState({ selectedGroups: selectedFilterGroup })
                                                                                                    }} />
                                                                                            </MDBCol>
                                                                                        </MDBRow>
                                                                                    </MDBCol>
                                                                                    <MDBCol className="pointer" md={1} disabled={(field?.compValue === 'null' || field?.compValue === 'not null') ? false : field?.compValue === '>' ? !field?.value1 : field?.compValue === '<' ? !field?.value2 : !(field?.value1 && field?.value2)}>
                                                                                        <MDBIcon icon="plus"
                                                                                            onClick={() => {
                                                                                                this.handleSelectedFilterValue(this.state.entity, item?.text, subFilterItem?.type, field?.value, field?.text, field?.type, { 'compValue': field?.compValue || null, 'value1': field?.value1, 'value2': field?.value2 || null, ...(field?.typeDropDown ? { typeDropDown: field?.typeDropDown || null } : {}), ...(field?.signType ? { signType: field?.signType || null } : {}), })
                                                                                                let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value1 = null
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].value2 = null
                                                                                                selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].compValue = 'between'
                                                                                                this.setState({ selectedGroups: selectedFilterGroup })
                                                                                            }}
                                                                                        />
                                                                                    </MDBCol>
                                                                                </MDBRow>}
                                                                            </div>}
                                                                        </MDBCol>
                                                                        <MDBCol md={1} className="pr-2 pointer">
                                                                            {field?.isExpand ? <MDBIcon icon="minus"
                                                                                onClick={() => {
                                                                                    let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                    selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].isExpand = false
                                                                                    this.setState({ selectedGroups: selectedFilterGroup })
                                                                                }} /> : <MDBIcon icon="plus"
                                                                                    onClick={() => {
                                                                                        if (field.type === 'Select' && !this.state[field?.optionName]) {
                                                                                            this.getLookUpValue(field.tableName, field.optionName)
                                                                                        }
                                                                                        let selectedFilterGroup = JSON.parse(JSON.stringify(this.state.selectedGroups))
                                                                                        selectedFilterGroup[filterIndex].filterCategoryTypes[subFilterIndex].filterCategories[fieldIndex].isExpand = true
                                                                                        this.setState({ selectedGroups: selectedFilterGroup })
                                                                                    }} />}
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </MDBRow>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol className='maxHeightClass'>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardText>
                                    <MDBRow>
                                        <MDBCol md={6} className="saved-search-filter">
                                            <SelectField
                                                id={"saved-search"}
                                                placeHolderText="-Saved Searches-"
                                                options={this.state.allSavedSearch?.map(item => ({ value: item?.advance_search_query_id, label: item?.name })) || []}
                                                value={this.state.selectedSavedSearch?.advance_search_query_id || null}
                                                onChange={(e) => {
                                                    this.setState({ selectedSavedSearch: this.state.allSavedSearch?.find(item => item?.advance_search_query_id === e.target.value) }, () => {
                                                        this.arrangeSavedQueryData(this.state.selectedSavedSearch, true)
                                                    })
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol md={3} > </MDBCol>
                                        <MDBCol md={1} className="column-filter p-0">
                                            <>
                                                <BasicButton
                                                    id={'adsearchcoulmnselectorbtn'}
                                                    className="columnsFilter"
                                                    type="inline"
                                                    variant="outlined"
                                                    text={"Columns"}
                                                    icon={"chevron-right"}
                                                    iconRight={true}
                                                    onClick={(e) => this.setState({ setColumns: e.currentTarget })}
                                                />
                                                <Popover
                                                    id={this.state.setColumns ? 'simple-popover' : null}
                                                    open={Boolean(this.state.setColumns)}
                                                    anchorEl={this.state.setColumns}
                                                    onClose={() => this.setState({ setColumns: null })}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            width: '600px',
                                                        }
                                                    }}
                                                >
                                                    <MDBContainer className='columnSelectorPopOver' disabled={this.state.isLoadingSets}><MDBRow>
                                                        <span>*Max 12 Columns can be selected.</span>
                                                        {this.state.availableColumns?.map((col, idxCol) => {
                                                            return <MDBCol md={4} key={idxCol + col?.value} >
                                                                <BasicCheckbox
                                                                    id={idxCol + col?.text + col?.value}
                                                                    label={col?.text}
                                                                    checked={col?.isChecked === 1}
                                                                    disabled={col?.def}
                                                                    onChange={(e) => {
                                                                        if (this.state.availableColumns?.filter(item => item?.isChecked)?.length === 12 ? !e.target.checked : true) {
                                                                            let availableColumns = JSON.parse(JSON.stringify(this.state.availableColumns))
                                                                            availableColumns[idxCol].isChecked = e.target.checked ? 1 : 0
                                                                            this.setState({ availableColumns: availableColumns, config: this.getTabelConfig(availableColumns) }, () => {
                                                                                if (this.state.count > 0 && !this.state.list[0]?.hasOwnProperty(availableColumns[idxCol].value)) {
                                                                                    let fromQuery = { ...this.state.fromQuery }
                                                                                    fromQuery[availableColumns[idxCol].tableName] = 1
                                                                                    this.setState({ fromQuery: fromQuery }, () => {
                                                                                        this.getAdvanceSearchData()
                                                                                    })
                                                                                }
                                                                            })
                                                                        }
                                                                    }} />
                                                            </MDBCol>
                                                        })}
                                                    </MDBRow>
                                                        <MDBRow className='mb-1'>
                                                            <BasicButton
                                                                type="inline"
                                                                variant="outlined"
                                                                text={"Close"}
                                                                className="FR MR1"
                                                                icon='times'
                                                                onClick={(e) => this.setState({ setColumns: null })}
                                                            />
                                                        </MDBRow>
                                                    </MDBContainer>
                                                </Popover>
                                            </>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <div className="filter-chips d-flex">
                                            <div className="filter-width">
                                                {this.state.personType && <Chip label={'Person Type : ' + this.state.personType} />}
                                                {this.state.selectedFilters?.map((criteria, criteriaIndex) => (
                                                    <>
                                                        <Chip
                                                            label={criteria?.uiShow}
                                                            onDelete={() => this.handleFilterDelete(criteria, criteriaIndex)}
                                                        />
                                                    </>
                                                ))}
                                            </div>
                                            {this.state.selectedFilters?.length > 0 ?
                                                <div className="d-flex">
                                                    <div className="clear-criteria-btn p-0">
                                                        <BasicButton
                                                            variant="outlined"
                                                            text="Clear Criteria"
                                                            type="inline"
                                                            onClick={() => {
                                                                this.setState({
                                                                    list: [],
                                                                    selectedFilters: [],
                                                                    whereQuery: null,
                                                                    fromQuery: this.getFromQueryForSort(),
                                                                    tempWhereQuery: null,
                                                                    page: 0,
                                                                    rowsPerPage: 10,
                                                                    count: 0,
                                                                    selectedSavedSearch: null
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="save-search-btn p-0">
                                                        {this.state.selectedFilters?.length > 0 &&
                                                            <>
                                                                <BasicButton
                                                                    id={'adsSearchSavedSearchNamepopover'}
                                                                    variant="outlined"
                                                                    text="Save Search Criteria"
                                                                    type="inline"
                                                                    onClick={(e) => { this.setState({ savedSearchPopver: e.currentTarget }) }}
                                                                />
                                                                <Popover
                                                                    id={this.state.savedSearchPopver ? 'simple-popover' : null}
                                                                    open={Boolean(this.state.savedSearchPopver)}
                                                                    anchorEl={this.state.savedSearchPopver}
                                                                    onClose={() => {
                                                                        this.setState({ savedSearchPopver: null })
                                                                    }}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    PaperProps={{
                                                                        style: {
                                                                            width: '300px',
                                                                        }
                                                                    }}
                                                                >
                                                                    <MDBContainer className='columnSelectorPopOver'>
                                                                        <MDBRow>
                                                                            <BasicTextField
                                                                                label="Enter name to save search criteria as"
                                                                                value={this.state.selectedSavedSearch?.name || null}
                                                                                onChange={(e) => {
                                                                                    let selectedSavedSearch = { ...this.state.selectedSavedSearch }
                                                                                    selectedSavedSearch.name = e.target.value?.length > 0 ? e.target.value : null
                                                                                    this.setState({ selectedSavedSearch: selectedSavedSearch })
                                                                                }}
                                                                            />
                                                                        </MDBRow>
                                                                        <MDBRow className='mt-2 mb-1'>
                                                                            <MDBCol md={6}>
                                                                                <BasicButton
                                                                                    variant="contained"
                                                                                    type="inline"
                                                                                    icon={"save"}
                                                                                    text={'Save'}
                                                                                    className="FR MR1"
                                                                                    disabled={!this.state.selectedSavedSearch?.name || this.state.selectedFilters?.length === 0}
                                                                                    onClick={() => {
                                                                                        this.postSavedSearch(this.state.selectedSavedSearch?.advance_search_query_id || null, this.state.selectedSavedSearch?.name, 0)
                                                                                    }}
                                                                                />
                                                                            </MDBCol>
                                                                            <MDBCol md={6}>
                                                                                <BasicButton
                                                                                    className="FR MR1"
                                                                                    type="inline"
                                                                                    variant="outlined"
                                                                                    icon={"times"}
                                                                                    text={'Close'}
                                                                                    onClick={() => this.setState({ savedSearchPopver: null })}
                                                                                />
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                    </MDBContainer>
                                                                </Popover>

                                                                {this.state.selectedSavedSearch?.advance_search_query_id && <BasicButton
                                                                    variant="outlined"
                                                                    text="Delete Search Criteria"
                                                                    type="inline"
                                                                    onClick={() => {
                                                                        this.postSavedSearch(this.state.selectedSavedSearch?.advance_search_query_id || null, this.state.selectedSavedSearch?.name, 1)
                                                                    }}
                                                                />}
                                                            </>
                                                        }
                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                    </MDBRow>
                                    <MDBRow>
                                        <div className={"AdvancedSearchTable"} id={"AdvancedSearch"}>
                                            {this.state.isLoadingSets ? <CircularProgress className={'loaderPosition'} /> : null}
                                            <TableComponent
                                                list={this.state.list || []}
                                                config={this.state.config}
                                                sortCallback={this.handleSortColumn}
                                                stickyHeader={true}
                                                hyperLinkNavigationCallback={(item, node) => this.handleNavigation(item, node)}
                                            />
                                            <TablePagination
                                                component="div"
                                                count={this.state.count}
                                                rowsPerPageOptions={[10, 20, 50, 100]}
                                                page={this.state.page}
                                                onChangePage={(e, page) => {
                                                    this.setState({ page: page }, () => {
                                                        if (this.state.count > 0) this.getAdvanceSearchData()
                                                    })
                                                }}
                                                rowsPerPage={this.state.rowsPerPage}
                                                onChangeRowsPerPage={(e) => {
                                                    this.setState({ rowsPerPage: e.target.value, page: 0 }, () => {
                                                        if (this.state.count > 0) this.getAdvanceSearchData()
                                                    })
                                                }}
                                            />
                                        </div>
                                    </MDBRow>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default withRouter(withUserContext(AdvanceSearch));